class Ethereum {
  url = 'https://api.blockchair.com/ethereum/'
  axios = require('axios')
  BigNumber = require('bignumber.js')
  addrValidator = require('multicoin-address-validator')
  transactions = []
  tokens = []

  axiosConfig(config = this.axios.defaults) {
    config.headers['content-type'] = 'application/json'
    delete config.headers['Authorization']

    return config
  }

  checkTxn(txn) {
    return /^.{64,}$/.test(txn)
  }

  checkAddress(addr) {
    return this.addrValidator.validate(addr, 'ETH')
  }

  async getBalance(addr) {
    return await this.axios
      .get(
        `${this.url}dashboards/address/${addr}?limit=20,0&erc_20=precise&offset=0&assets_in_usd=true`, this.axiosConfig(),
      )
      .then(async (res) => {
        const [key] = Object.keys(res.data.data)

        this.transactions = res.data.data[key].calls
        this.tokens = res.data.data[key].layer_2.erc_20

        return res.data.data[key].address.balance / 1e18
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  async getTransactions(payload) {
    if (payload.new_req) {
      return await this.axios
        .get(
          `${this.url}dashboards/address/${payload.address}?offset=${payload.page}&limit=${payload.row}`, this.axiosConfig(),
        )
        .then(async (res) => {
          return res.data.data[payload.address].calls
        })
        .catch((err) => {
          throw new Error(err)
        })
    } else
      return this.transactions
  }

  async getTransaction(txn) {
    return await this.axios
      .get(
        `${this.url}dashboards/transaction/${txn}?erc_20=true&trace_mempool=true`, this.axiosConfig(),
        // `${this.url}calls?q=recipient(${txn}),failed(false)&a=sum(value)`, this.axiosConfig(),
      )
      .then((res) => {
        return [res.data.data[txn]]
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  async getTransfers(addr) {
    return []
  }

  async getTokens(addr) {
    return this.tokens
  }
}

module.exports.Ethereum = Ethereum
