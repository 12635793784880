class Litecoin {
  url = 'https://amhora.com/v1/litecoin/'
  axios = require('axios')
  BigNumber = require('bignumber.js')
  addrValidator = require('multicoin-address-validator')

  instance = this.axios.create()

  axiosConfig(config = this.instance.defaults) {
    delete config.headers
    config = {
      headers: {
        'content-type': 'application/json, text/plain, */*',
      },
    }

    return config
  }

  checkTxn(txn) {
    return /^[a-fA-F0-9]{64}$/.test(txn)
  }

  checkAddress(addr) {
    return this.addrValidator.validate(addr, 'LTC')
  }

  async getBalance(addr) {
    return await this.instance.get(
      `${this.url}address/${addr}`, this.axiosConfig(),
    ).then(async (res) => {
      return res.data.currentBalance
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransactions(payload) {
    return await this.instance.get(
      `${this.url}address/${payload.address}/transactions?page=${payload.page}&pageSize=${payload.row}&conf=all&order=desc`, this.axiosConfig(),
    ).then((res) => {
      return res.data.records
    }).catch((err) => {
      console.log(err.response.data)

      return []
    })
  }

  async getTransaction(txn) {
    return await this.instance.get(
      `${this.url}transaction/${txn}`, this.axiosConfig(),
    ).then((res) => {
      return [res.data]
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransfers(addr) {
    return []
  }

  async getTokens(addr) {
    return []
  }
}

module.exports.Litecoin = Litecoin
