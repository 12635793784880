class Tron {
  url = 'https://apilist.tronscan.org/api/'
  axios = require('axios')
  BigNumber = require('bignumber.js')
  addrValidator = require('multicoin-address-validator')
  tokens = []
  instance = this.axios.create()

  axiosConfig(config = this.instance.defaults) {
    delete config.headers
    config = {
      headers: {
        'content-type': 'text/plain;charset=UTF-8',
      },
    }

    return config
  }

  checkTxn(txn) {
    return /^[a-fA-F0-9]{64}$/.test(txn)
  }

  checkAddress(addr) {
    return this.addrValidator.validate(addr, 'TRX')
  }

  async getBalance(addr) {
    return await this.instance.get(
      `${this.url}account?address=${addr}&source=true`, this.axiosConfig(),
    ).then(async (res) => {
      this.tokens = res.data.tokens

      return res.data.balance / 1000000
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransaction(txn) {
    return await this.instance.get(
      `${this.url}transaction-info?hash=${txn}`, this.axiosConfig(),
    ).then((res) => {
      return [res.data]
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransactions(payload) {
    return await this.instance.get(
      `${this.url}transaction?sort=-timestamp&count=true&limit=${payload.row}&start=${payload.page}&address=${payload.address}`, this.axiosConfig(),
    ).then((res) => {
      return res.data.data
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransfers() {
    return []
  }

  async getTokens(payload) {
    return this.tokens
  }
}

module.exports.Tron = Tron
