<template>
  <div>
    <v-row v-if="listTokens.length > 0">
      <v-col>
        <v-card
          class="common_background elevation-0 pa-2 mb-2 body-2"
        >
          <v-row>
            <v-col><h3 class="my-3  pl-2">Tokens</h3></v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-for="token in tokens.slice(0, tokenSlice)"
              :key="token.key"
              cols="6"
              sm="4"
              md="3"
              lg="2"
            >
              <v-card
                class="common_background elevation-0 pa-2"
              >
                <div class="adjust-center">
                  <img
                    v-if="token.tokenLogo"
                    :alt="token.name"
                    :lazy-src="token.tokenLogo"
                    class="token-img mr-1"
                    :src="token.tokenLogo"
                    @error="helper.imgerror"
                  />
                  <span>{{ helper.capitalizeFirstLetter(token.tokenName) }}</span>
                </div>
                <p class="subtitle-2 mb-0 caption">
                  <span class="">
                    {{ token.balance / 1000000 }}
                    {{ token.tokenAbbr ? token.tokenAbbr.toUpperCase() : '' }}
                  </span>
                </p>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn large class="primary uncapitalize body-2 px-4" @click="onLoadMoreTokens()">
                <v-icon>mdi-refresh</v-icon>
                <span class="">{{ $t('LoadMore') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isAddress">
        <v-row>
          <v-col cols="6" md="3" lg="2">
            <v-btn-toggle
              class="fill-width d-flex v-btn-group v-btn-group--4"
              mandatory
              dense
            >
              <v-btn
                v-for="(item, idx) in btnTabs"
                :key="idx"
                :ripple="false"
                :value="item.value"
                class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
                @click="onChangeTab(item)"
              >
                {{ item.name }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            cols="6"
            md="3"
            offset-md="6"
            lg="2"
            offset-lg="8"
          >
            <v-row dense align="center">
              <v-col cols="2" lg="2" offset="8">
                <v-btn
                  block
                  :value="true"
                  class="float-right no-hover-bg"
                  @click="onPreviousPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
                </v-btn>
              </v-col>
              <v-col cols="2" lg="2">
                <v-btn
                  block
                  :value="false"
                  class="float-right no-hover-bg"
                  @click="onNextPage"
                >
                  <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :items="listTransactions"
              :loading="loadingTransactions"
              :loader-height="1"
              :headers-length="10"
              no-data-text="No Record Found."
              hide-default-footer
              disable-pagination
              mobile-breakpoint="0"
              hide-default-header
            >
              <template v-slot:header="">
                <thead class="v-data-table-header">
                  <tr>
                    <th width="15%" class="sticky">{{ $t('Hash') }} </th>
                    <th width="5%">{{ $t('Block') }} </th>
                    <th width="10%">{{ $t('Date') }} </th>
                    <th width="15%">{{ $t('From') }}</th>
                    <th width="15%">{{ $t('To') }}</th>
                    <th width="10%">{{ $t('TransactionType') }}</th>
                    <th width="10%">{{ $t('Status') }}</th>
                    <th width="5%">{{ $t('Result') }}</th>
                    <th width="5%">{{ $t('Token') }}</th>
                    <th width="10%" class="text-right">{{ $t('Amount') }}</th>
                  </tr>
                </thead>
              </template>
              <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <div>
                      <a class="ellipsis body-2" @click="onGetTransaction(item.hash)">
                        <span>{{ helper.truncateString(item.hash, 30) }}</span>
                      </a>
                    </div>
                  </td>
                  <td>{{ item.block }}</td>
                  <td class="text-left currency-details">
                    <span class="ellipsis body-2">
                      {{ helper.utcDate(new Date(item.timestamp)) }}
                    </span>
                  </td>
                  <td>
                    <a v-if="item.ownerAddress !== search" class="ml-2" @click="onGetBalance(item.ownerAddress)">
                      <span class="float-left">{{ helper.truncateString(item.ownerAddress, 20) }}</span>
                    </a>
                    <span v-else class="float-left">{{ helper.truncateString(item.ownerAddress, 20) }}</span>
                  </td>
                  <td>
                    <a v-if="item.toAddress !== search" class="ml-2" @click="onGetBalance(item.toAddress)">
                      <span class="float-left">{{ helper.truncateString(item.toAddress, 20) }}</span>
                    </a>
                    <span v-else class="float-left">{{ helper.truncateString(item.toAddress, 20) }}</span>
                  </td>
                  <td>
                    {{ onGetTransactionType(item.contractType) }}
                  </td>
                  <td>
                    <div v-if="item.confirmed">
                      <v-icon class="secondary_3--text">
                        mdi-checkbox-marked-outline
                      </v-icon>
                      <span>{{ $t('Confirmed') }}</span>
                    </div>
                    <div v-else>
                      <v-icon class="grey--text">
                        mdi-checkbox-marked-outline
                      </v-icon>
                      <span>{{ $t('Unconfirmed') }}</span>
                    </div>
                  </td>
                  <td>
                    {{ item.result }}
                  </td>
                  <td class="text-right">
                    <div v-if="item.tokenInfo" class="d-flex pt-2">
                      <div>
                        <img
                          :alt="item.tokenInfo.tokenAbbr"
                          :lazy-src="item.tokenInfo.tokenLogo"
                          :src="item.tokenInfo.tokenLogo"
                          class="currency-logo"
                          @error="helper.imgerror"
                        />
                      </div>
                      <div class="ml-2">
                        <span>{{ helper.capitalizeFirstLetter(item.tokenInfo.tokenAbbr) }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-right">
                    <v-chip
                      v-if="isAddress"
                      label
                      :class="` text-right
                        ${ item.toAddress === search ? 'white--text secondary_3': 'white--text red'}
                      `"
                    >
                      {{ helper.convertToBigNumber(convertAmount(item.amount)) }}
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else>
        <v-row>
          <v-col>
            <v-card
              v-for="item in list"
              :key="`${item.hash}-${Math.random()}`"
              class="common_background elevation-0 pa-2 mb-2"
            >
              <v-row dense>
                <v-col cols="12" sm="6" class="flex-grow-1">
                  <p class="mb-0 body-2">
                    <span class=" float-left">{{ $t('Hash') }}:</span>
                    <a class="float-right" @click="onGetTransaction(item.hash)">
                      {{ item.hash }}
                    </a>
                  </p>
                </v-col>
                <v-col cols="12" sm="3" class="flex-grow-0">
                  <p class="body-2 mb-0 text-right" >
                    <span class="float-left">{{ $t('Block') }}:</span>
                    <span class="float-right ellipsis secondary--text">{{ item.block }}</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="3" class="flex-grow-0">
                  <p class="body-2 mb-0">
                    <span class=" float-left">{{ $t('Date') }}:</span>
                    <span class=" float-right secondary--text">
                      {{ helper.nonUTCDate(new Date(item.timestamp)) }}
                    </span>
                  </p>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <p class="body-2 mb-0">
                    <span class="">{{ $t('From') }}: </span>
                    <span v-if="item.ownerAddress === search" class="float-right txn">{{ item.ownerAddress }}</span>
                    <a v-else @click="onGetBalance(item.ownerAddress)">
                      <span class="float-right">{{ item.ownerAddress }}</span>
                    </a>
                  </p>
                </v-col>
                <v-col cols="12" sm="6">
                  <p class="body-2 mb-0">
                    <span class="float-left ">{{ $t('To') }}:</span>
                    <span v-if="item.toAddress === search" class="float-right txn">{{ item.toAddress }}</span>
                    <a v-else class="ml-2" @click="onGetBalance(item.toAddress)">
                      <span class="float-right txn">{{ item.toAddress }}</span>
                    </a>
                  </p>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <p class="body-2 mb-0">
                    <span class="float-left ">{{ $t('Fee') }}:</span>
                    <span class="float-right secondary--text">{{ item.fee ? convertAmount(item.fee) : 0 }}</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="3">
                  <p>
                    <span class="float-left ">{{ $t('Status') }}:</span>
                    <span class="float-right secondary--text">{{ item.confirmed ? $t('Confirmed') : $t('Unconfirmed') }}</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="3">
                  <p class="body-2 mb-0">
                    <span class="float-left ">{{ $t('Result') }}:</span>
                    <span class="float-right secondary--text"> {{ item.contractRet }}</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-chip
                    label
                    :class="`float-right subtitle-1 font-weight-
                      ${ item.result === 'SUCCESS' || item.contractRet === 'SUCCESS' ? 'green--text': 'red--text'}
                    `"
                  >
                    <span v-if="item.contractData.amount" class="float-right">{{ convertAmount(item.contractData.amount) }}</span>
                    <span v-else>
                      <span v-if="item.amount" class="float-right">{{ convertAmount(item.amount) }}</span>
                      <span v-else-if="item.contractData.call_value" class="float-right">{{ convertAmount(item.contractData.call_value) }}</span>
                      <span v-else class="float-right">0</span>
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import helper from '@/utils/helper'

export default {
  props: {
    'list': { type: Array, default: () => [] },
    'tokens': { type: Array, default: () => [] },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
    'currency': { type: String, default: () => '' },
  },
  data() {
    return {
      lib: require('@/utils/crypto/trx.js'),
      helper: helper,
      listTransactions: this.list,
      listTokens: this.tokens,
      pageTransactions: 0,
      loadingTransactions: false,
      rowsPerPage: 50,
      tokenSlice: 12,
      position: -1,
      selected_tab: { name: 'Transactions', value: 'transactions' },
      btnTabs: [
        { name: 'Transactions', value: 'transactions' },
      ],
      selected_action: { name: 'Actions', value: 'actions' },
      btnActions: [
        { name: 'Actions', value: 'actions' },
        { name: 'Traces', value: 'traces' },
      ],
      contractTypes: [
        { id: 1, name: 'Transfer TRX' },
        { id: 2, name: 'Transfer TRC10' },
        { id: 4, name: 'Vote' },
        { id: 7, name: 'Claim Rewards' },
        { id: 8, name: 'Update Account Permission' },
        { id: 11, name: 'Freeze TRX' },
        { id: 12, name: 'Unfreeze TRX' },
        { id: 13, name: 'Tobols' },
        { id: 31, name: 'Trigger Smart Contracts' },
      ],
    }
  },
  methods: {
    onLoadMoreTokens() {
      this.tokenSlice += 12
    },
    onPreviousPage() {
      if (this[`page${this.selected_tab.name}`] <= 1) {
        this[`page${this.selected_tab.name}`] = 0
      } else {
        this[`page${this.selected_tab.name}`] -= 1 * this.rowsPerPage
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`page${this.selected_tab.name}`] += 1 * this.rowsPerPage
      this.onPaginate()
    },
    async onPaginate() {
      const lib = new this.lib['Tron']()

      this[`loading${this.selected_tab.name}`] = true

      const tempTxns = await lib.getTransactions({
        row: this.rowsPerPage,
        page: this[`page${this.selected_tab.name}`],
        address: this.search,
      })

      this.listTransactions = tempTxns

      this[`loading${this.selected_tab.name}`] = false
    },
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    onGetTransactionType(id) {
      return this.contractTypes.find((o) => o.id === id).name
    },
    convertAmount(amount, decimal = 8) {
      return (amount / 1000000).toLocaleString('en', { useGrouping: false, minimumFractionDigits: 6 })
    },
  },
}
</script>
