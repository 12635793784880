<template>
  <v-row>
    <v-col v-if="isAddress">
      <v-row v-if="listTokens.length > 0">
        <v-col>
          <v-card
            class="common_background elevation-0 pa-2 mb-2 body-2"
          >
            <v-row>
              <v-col><h3 class="my-3 bold pl-2">{{ $t('Tokens') }}</h3></v-col>
            </v-row>
            <v-row dense>
              <v-col
                v-for="token in listTokens.slice(0, tokenSlice)"
                :key="token.token_address"
                cols="6"
                sm="4"
                md="3"
                lg="2"
              >
                <v-card
                  class="common_background elevation-0 pa-2"
                >
                  <div >
                    <a class="text-right adjust-center" @click="onGetBalance(token.token_address)">
                      <img
                        class="token-img mr-1"
                        :lazy-src="helper.extractEosWaxTokenImgUrl(token.logo)"
                        :src="helper.extractEosWaxTokenImgUrl(token.logo)"
                        @error="helper.imgerror"
                      />
                      <span v-if="token.token_name !== ''" class="mt-n5">{{ token.token_name }}</span>
                      <span v-else class="ellipsis">{{ token.token_address.substr(0,8) }}</span>
                    </a>
                  </div>
                  <p class="subtitle-2 mb-0 caption">
                    <span class=" bold">{{ convertAmount(token.balance, token.token_decimals) }} {{ token.token_symbol }}</span>
                  </p>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn large class="primary uncapitalize body-2 px-4" @click="onLoadMoreTokens()">
                  <v-icon>mdi-refresh</v-icon>
                  <span class="">{{ $t('LoadMore') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="isAddress">
        <v-col cols="6" md="3" lg="2">
          <v-btn-toggle
            class="fill-width d-flex v-btn-group v-btn-group--4"
            mandatory
            dense
          >
            <v-btn
              v-for="(item, idx) in btnTabs"
              :key="idx"
              :ripple="false"
              :value="item.value"
              class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
              @click="onChangeTab(item)"
            >
              {{ item.name }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          cols="6"
          md="3"
          offset-md="6"
          lg="2"
          offset-lg="8"
        >
          <v-row dense align="center">
            <v-col cols="2" lg="2" offset="8">
              <v-btn
                block
                :value="true"
                class="float-right no-hover-bg common_background"
                @click="onPreviousPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
              </v-btn>
            </v-col>
            <v-col cols="2" lg="2">
              <v-btn
                block
                :value="false"
                class="float-right no-hover-bg common_background"
                @click="onNextPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :items="listTransactions"
            :loading="loadingTransactions"
            :loader-height="1"
            :headers-length="7"
            no-data-text="No Record Found."
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            hide-default-header
          >
            <template v-slot:header="">
              <thead class="v-data-table-header">
                <tr>
                  <th width="15%" class="sticky">{{ $t('Hash') }} </th>
                  <th width="8%">{{ $t('Block') }} </th>
                  <th width="10%">{{ $t('Date') }} </th>
                  <th width="5%">{{ $t('Action') }}</th>
                  <th width="25%"></th>
                  <th width="5%" class="text-center">{{ $t('Success') }}</th>
                  <th width="10%" class="text-right">{{ $t('Amount') }}</th>
                </tr>
              </thead>
            </template>
            <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <div>
                    <a class="ellipsis body-2" @click="onGetTransaction(item.transaction_hash)">
                      <span>{{ helper.truncateString(item.transaction_hash, 30) }}</span>
                    </a>
                  </div>
                </td>
                <td>
                  <span class="body-2">{{ item.block_id > 0 ? item.block_id : '' }}</span>
                </td>
                <td>
                  <span class="body-2">{{ item.time }}</span>
                </td>
                <td>
                  <v-chip
                    label
                    :class="`body-2
                      ${ item.recipient === search ? 'secondary_3--text highlight--success': 'red--text highlight--failed'}
                    `"
                  >
                    {{ `${ item.recipient === search ? $t('Received') : $t('Sent') }` }}
                  </v-chip>
                </td>
                <td>
                  <a v-if="item.sender !== search " class="ellipsis" @click="onGetBalance(item.sender)">
                    <span class="body-2">{{ helper.truncateString(item.sender, 30) }}</span>
                  </a>
                  <span v-else class="body-2">{{ helper.truncateString(item.sender, 30) }}</span>
                  <v-icon class="subtitle-1">mdi-arrow-right</v-icon>
                  <a v-if="item.recipient !== search " class="ellipsis" @click="onGetBalance(item.recipient)">
                    <span class="body-2">{{ helper.truncateString(item.recipient, 30) }}</span>
                  </a>
                  <span v-else class="body-2">{{ helper.truncateString(item.recipient, 30) }}</span>
                </td>
                <td class="text-center">
                  <v-icon v-if="item.transferred" class="secondary_3--text">
                    mdi-checkbox-marked-outline
                  </v-icon>
                  <v-icon v-else class="red--text">
                    mdi-close-box-outline
                  </v-icon>
                </td>
                <td class="text-right">
                  <v-chip
                    label
                    :class="` text-right
                      ${ item.recipient === search ? 'white--text secondary_3': 'white--text red'}
                    `"
                  >
                    {{ helper.convertToBigNumber(convertAmount(item.value, 18), 6) }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-card
        v-if="!listTransactions[0]"
        outlined
        class="common_background elevation-0 pa-2 mb-2"
      >
        <v-row>
          <v-col class="text-center">{{ $t('NoTransactionFound') }}</v-col>
        </v-row>
      </v-card>
      <v-card
        v-else
        outlined
        class="common_background elevation-0 pa-2 mb-2"
      >
        <v-row dense>
          <v-col cols="12" sm="6" class="flex-grow-1">
            <p class="mb-0">
              <span class="bold float-left">{{ $t('Hash') }}:</span>
              <a class="text-right" @click="onGetTransaction(listTransactions[0].transaction.hash)"><span class="float-right">{{ listTransactions[0].transaction.hash }}</span></a>
            </p>
          </v-col>
          <v-col cols="12" sm="3" class="flex-grow-0">
            <p class="mb-0">
              <span class="bold float-left">{{ $t('Block') }}:</span>
              <span class="float-right secondary--text">{{ listTransactions[0].transaction.block_id }}</span>
            </p>
          </v-col>
          <v-col cols="12" sm="3" class="flex-grow-0">
            <p class="mb-0">
              <span class="bold float-left">{{ $t('Date') }}:</span>
              <span class="float-right secondary--text">{{ listTransactions[0].transaction.date }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" >
            <div class="mr-2">
              <p class="mb-0">
                <span class="bold">{{ $t('From') }}:</span>
                <a class="text-right" @click="onGetBalance(listTransactions[0].transaction.sender)">
                  <span class="float-right ellipsis">{{ listTransactions[0].transaction.sender }}</span>
                </a>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" >
            <div class="mr-2">
              <p class="mb-0">
                <span class="bold">{{ $t('To') }}:</span>
                <a class="text-right" @click="onGetBalance(listTransactions[0].transaction.recipient)">
                  <span class="float-right ellipsis">{{ listTransactions[0].transaction.recipient }}</span>
                </a>
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <div class="mr-2">
              <p class="pb-0">
                <span class="float-left bold">{{ $t('Fee') }}:</span>
                <span class="float-right secondary--text">{{ convertGas(listTransactions[0].transaction.gas_used, listTransactions[0].transaction.gas_price) }}</span>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <v-chip
              label
              :class="`
                mt-2 float-right subtitle-1 font-weight-bold green--text
              `"
            >
              <span class="float-right">
                {{ parseFloat(listTransactions[0].transaction.value) }}
              </span>
            </v-chip>
          </v-col>
        </v-row>
      </v-card>
      <v-row dense class="mt-3">
        <v-col>
          <h4>
            {{ $t('ERC20') }} {{ $t('Token') }} {{ $t('Transfers') }}
          </h4>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-data-table
            :items="listTransactions[0].layer_2.erc_20"
            :loading="loadingTransactions"
            :loader-height="1"
            :headers-length="7"
            no-data-text="No Record Found."
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            hide-default-header
          >
            <template v-slot:header="">
              <thead class="v-data-table-header">
                <tr>
                  <th width="20%">{{ $t('Token') }} </th>
                  <th width="5%"></th>
                  <th width="25%">{{ $t('From') }}</th>
                  <th width="25%">{{ $t('To') }}</th>
                  <th width="25%" class="text-right">{{ $t('Value') }}</th>
                </tr>
              </thead>
            </template>
            <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <span class="secondary--text">{{ item.token_name }}</span>
                </td>
                <td>
                  <div class="ml-1 greyText--text">
                    <small>{{ item.token_symbol }}</small>
                  </div>
                </td>
                <td>
                  <a class="ellipsis" @click="onGetBalance(item.sender)">
                    <span class="body-2">{{ helper.truncateString(item.sender, 30) }}</span>
                  </a>
                </td>
                <td>
                  <a class="ellipsis" @click="onGetBalance(item.recipient)">
                    <span class="body-2">{{ helper.truncateString(item.recipient, 30) }}</span>
                  </a>
                </td>
                <td class="text-right">
                  <span class="body-2 secondary--text">{{ item.value_approximate }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import helper from '@/utils/helper'

export default {
  props: {
    'transactions': { type: Array, default: () => [] },
    'tokens': { type: Array, default: () => [] },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
  },
  data() {
    return {
      lib: require('@/utils/crypto/eth.js'),
      helper: helper,
      listTransactions: this.transactions,
      listTokens: this.tokens ? this.removeTokens(this.tokens) : [],
      pageTransactions: 0,
      loadingTransactions: false,
      rowsPerPage: 100,
      tokenSlice: 12,
      selected_tab: { name: 'Transactions', value: 'transactions' },
      btnTabs: [
        { name: 'Transactions', value: 'transactions' },
      ],
    }
  },
  methods: {
    onLoadMoreTokens() {
      this.tokenSlice += 12
    },
    onPreviousPage() {
      if (this[`page${this.selected_tab.name}`] <= 1) {
        this[`page${this.selected_tab.name}`] = 0
      } else {
        this[`page${this.selected_tab.name}`] -= 1 * this.rowsPerPage
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`page${this.selected_tab.name}`] += 1 * this.rowsPerPage
      this.onPaginate()
    },
    async onPaginate() {
      const lib = new this.lib['Ethereum']()

      this[`loading${this.selected_tab.name}`] = true

      const tempTxns = await lib.getTransactions({
        row: this.rowsPerPage,
        page: this[`page${this.selected_tab.name}`],
        address: this.search,
        new_req: true,
      })

      this.listTransactions = tempTxns

      this[`loading${this.selected_tab.name}`] = false
    },
    removeTokens(arr) {
      return arr.filter((item, index) => {
        return item.token_name !== '' && item.token_symbol !== ''
      })
    },
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    convertAmount(amount, decimal) {
      return amount / helper.padTrailingZeros(1, decimal + 1)
    },
    convertAmountFromInput(input) {
      const hash = input.substr(3, 10)
      const addrVal = input.substr(10, input.length).match(/.{64}/g)
      const divisor = Math.floor((addrVal.length - 3) / 2) + 1
      const tempAddresses = addrVal.slice(3, divisor + 2)
      const tempValues = addrVal.slice(divisor + 3, addrVal.length)
      const values = []

      for (const [index, value] of tempValues.entries()) {
        values.push(parseInt(value, 16))
      }

      return input
    },
    convertGas(gas, gasPrice) {
      return (gas * gasPrice) / 1e18
    },
  },
}
</script>
