<template>
  <v-container fluid>
    <v-row dense class="mb-4 mx-1">
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <h1>{{ `${ $t('Explorer') }` }}</h1>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-row dense class="float-top">
              <v-col cols="12" sm="2" md="2" lg="1">
                <Menu
                  :button-size="`primary no-hover-bg`"
                  :selected-text="selected_currency.currency"
                  :list-items="currencies"
                  key-item="currency"
                  @click="onSelectCurrency($event)"
                />
              </v-col>
              <v-col cols="12" sm="9" md="7" lg="5">
                <v-text-field
                  v-model="search"
                  type="text"
                  hide-details
                  filled
                  dense
                  class="pt-0 mt-0"
                  :placeholder="$t('Search')"
                  :append-icon="`icon-search1`"
                  @keydown="onFilterKeys"
                />
              </v-col>
            </v-row>
            <v-divider class="my-2"/>
            <Loader v-if="loading" class="pt-16" :size="50"/>
            <v-row v-else-if="!loading && done_fetch && !has_error" class="mt-4">
              <v-col cols="12">
                <v-row v-if="is_address">
                  <v-col cols="12">
                    <v-card class="pa-2 pa-sm-5 common_background elevation-0">
                      <v-row dense>
                        <v-col cols="12" sm="3" md="2" lg="1">
                          <span class="grey--text font-weight-medium">Address:</span>
                        </v-col>
                        <v-col cols="12" sm="9">
                          <p class="secondary--text">{{ search }}</p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="3" md="2" lg="1">
                          <span class="grey--text font-weight-medium">Balance:</span>
                        </v-col>
                        <v-col cols="12" sm="9">
                          <span class="">{{ helper.convertToBigNumber(balance, 8) }}</span>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="selected_currency.currency === 'BTC'">
                    <Btc
                      :list="listTxn"
                      :is-address="is_address"
                      :search="search"
                      :headers="headers"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                  <v-col v-if="selected_currency.currency === 'ETH'">
                    <Eth
                      :transactions="listTxn"
                      :is-address="is_address"
                      :search="search"
                      :tokens="tokens"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                  <v-col v-if="selected_currency.currency === 'WAX' || selected_currency.currency === 'EOS'">
                    <WaxEos
                      :list="listTxn"
                      :is-address="is_address"
                      :search="search"
                      :tokens="tokens"
                      :currency="selected_currency.currency"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                  <v-col v-if="selected_currency.currency === 'TRX'">
                    <Trx
                      :list="listTxn"
                      :is-address="is_address"
                      :search="search"
                      :tokens="tokens"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                  <v-col v-if="selected_currency.currency === 'DOT'">
                    <Dot
                      :transactions="listTxn"
                      :transfers="listTransfers"
                      :is-address="is_address"
                      :search="search"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                  <v-col v-if="selected_currency.currency === 'ADA'">
                    <Ada
                      :list="listTxn"
                      :is-address="is_address"
                      :search="search"
                      :headers="headers"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                  <v-col v-if="selected_currency.currency === 'LTC'">
                    <Ltc
                      :list="listTxn"
                      :is-address="is_address"
                      :search="search"
                      :headers="headers"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                  <v-col v-if="selected_currency.currency === 'VET'">
                    <Vet
                      :list="listTxn"
                      :is-address="is_address"
                      :search="search"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                  <v-col v-if="selected_currency.currency === 'BSC' || selected_currency.currency === 'MATIC'">
                    <Bnb
                      :transactions="listTxn"
                      :is-address="is_address"
                      :search="search"
                      :tokens="tokens"
                      @onGetBalance="onGetBalance"
                      @onGetTransaction="onGetTransaction"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="has_error && listTxn.length < 1" >
              <v-col>
                <p class="my-3 red--text font-weight-medium text-center">{{ message }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Loader from '@/components/molecules/Loader'
import Btc from '@/components/organisms/transactions/Btc'
import Eth from '@/components/organisms/transactions/Eth'
import WaxEos from '@/components/organisms/transactions/WaxEos'
import Trx from '@/components/organisms/transactions/Trx'
import Dot from '@/components/organisms/transactions/Dot'
import Ada from '@/components/organisms/transactions/Ada'
import Ltc from '@/components/organisms/transactions/Ltc'
import Vet from '@/components/organisms/transactions/Vet'
import Bnb from '@/components/organisms/transactions/Bnb'
import Menu from '@/components/atoms/Menu'
import helper from '@/utils/helper'
import _ from 'lodash'

export default {
  name:'Explorer',
  components: {
    Menu,
    Loader,
    Btc,
    Eth,
    WaxEos,
    Trx,
    Dot,
    Ada,
    Ltc,
    Vet,
    Bnb,
  },
  data: () => ({
    helper: helper,
    lib: null,
    balance: 0,
    txn: [],
    listTxn: [],
    tokens: [],
    listTransfers: [],
    search: '',
    message: '',
    done_fetch: null,
    loading: false,
    has_error: false,
    is_address: null,
    row: 50,
    page: 0,
    currencies: [
      { value: 'Cardano', currency: 'ADA' },
      { value: 'Bitcoin', currency: 'BTC' },
      { value: 'BinanceCoin', currency: 'BSC' },
      { value: 'Polkadot', currency: 'DOT' },
      { value: 'Eos', currency: 'EOS' },
      { value: 'Ethereum', currency: 'ETH' },
      { value: 'Litecoin', currency: 'LTC' },
      { value: 'Polygon', currency: 'MATIC' },
      { value: 'Tron', currency: 'TRX' },
      { value: 'Vechain', currency: 'VET' },
      { value: 'Wax', currency: 'WAX' },
    ],
    selected_currency: { value: 'Cardano', currency: 'ADA' },
    'headers': [
      {
        text: 'Hash',
        value: '',
        align: 'left',
        width: '30%',
        sortable: false,
        class: 'sticky',
      },
      {
        text: 'Block',
        value: '',
        align: 'left',
        width: '10%',
        sortable: false,
      },
      {
        text: 'Date',
        value: '',
        align: 'left',
        width: '15%',
        sortable: false,
      },
      {
        text: 'Action',
        value: '',
        align: 'left',
        width: '15%',
        sortable: false,
      },
      {
        text: 'Fee',
        value: '',
        align: 'right',
        width: '15%',
        sortable: false,
      },
      {
        text: 'Amount',
        value: '',
        align: 'right',
        width: '15%',
        sortable: false,
      },
    ],
  }),
  mounted() {
    this.$store.commit('overview/CANCEL_REQUEST')
    this.lib = require(`@/utils/crypto/${this.selected_currency.currency.toLowerCase()}.js`)
  },
  beforeDestroy() {
    this.lib = null
  },
  methods: {
    onFilterKeys(e) {
      const key = e.keyCode

      if (e.key === 'Enter') this.onSubmitForm()
      else if (
        (key >= 48 && key <= 90) || (key >= 186 && key <= 222) ||
        key === 32 || //space
        key === 8 || //backspace
        key === 46 // delete
      ) {
        this.onChangeSearch()
      }
    },
    async onGetBalance(addr) {
      this.onFetchBalance(addr)
    },
    async onGetTransaction(txid) {
      this.loading = true
      this.is_address = false
      const lib = new this.lib[this.selected_currency.value]()

      this.search = txid

      await lib.getTransaction(txid).then((res) => {
        this.listTxn = res
      }).catch((err) => {
        console.log(err)

        this.has_error = true
        this.listTxn = {}
        this.message = this.$t('InvalidAddressTransaction')
      })

      this.listTransfers = {}
      this.tokens = []
      this.loading = false
      this.done_fetch = true
    },
    async onSubmitForm() {
      const lib = new this.lib[this.selected_currency.value]()

      this.has_error = false
      if (lib.checkAddress(this.search)) {
        this.onFetchBalance(this.search)
      } else if (lib.checkTxn(this.search)) {
        this.onGetTransaction(this.search)
      } else {
        this.is_address = null
        this.has_error = true
        this.listTxn = []
        this.message = this.$t('InvalidAddressTransaction')
      }
    },
    async onFetchBalance(addr) {
      this.loading = true
      this.search = addr
      this.is_address = true
      this.listTxn = []
      const lib = new this.lib[this.selected_currency.value]()

      this.balance = await lib.getBalance(addr)

      this.listTxn = await lib.getTransactions({
        row: this.row,
        page: this.page,
        address: addr,
      })

      this.listTransfers = await lib.getTransfers({
        row: this.row,
        page: this.page,
        address: addr,
      })

      this.tokens = await lib.getTokens({
        row: this.row,
        page: this.page,
        address: addr,
      })

      this.loading = false
      this.done_fetch = true
    },
    onSelectCurrency(item) {
      this.done_fetch = null
      this.selected_currency = item
      this.has_error = false
      this.lib = require(`@/utils/crypto/${item.currency.toLowerCase()}.js`)
    },
    onChangeSearch() {
      this.$nextTick(() => {
        this.loading = false
        this.balance = 0
        this.listTxn = []
        this.tokens = []
        this.listTransfers = []
        this.done_fetch = null
      })
    },
  },
}
</script>

<style lang="scss" >
.bold {
  font-weight: bold;
}

.ellipsis {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 400px) {
    width: 65%;
  }
  @media (max-width: 300px) {
    width: 40%;
  }
}

p {
  line-height: 20px;
}

.token-img {
  height: 20px;
  width: 20px;
}
</style>
