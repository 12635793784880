<template>
  <v-row>
    <v-col v-if="isAddress">
      <v-row>
        <v-col cols="8" md="3" lg="2">
          <v-btn-toggle
            class="fill-width d-flex v-btn-group v-btn-group--2"
            mandatory
            dense
          >
            <v-btn
              v-for="(item, idx) in btnTabs"
              :key="idx"
              :ripple="false"
              :value="item.value"
              class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
              @click="onChangeTab(item)"
            >
              {{ item.name }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          cols="4"
          md="3"
          offset-md="6"
          lg="2"
          offset-lg="8"
        >
          <v-row dense align="center">
            <v-col
              cols="6"
              sm="3"
              offset-sm="6"
            >
              <v-btn
                block
                :value="true"
                class="float-right no-hover-bg"
                @click="onPreviousPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              sm="3"
            >
              <v-btn
                block
                :value="false"
                class="float-right no-hover-bg"
                @click="onNextPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :items="listTransactions"
            :loading="loadingTransactions"
            :loader-height="1"
            :headers="headers"
            :headers-length="6"
            no-data-text="No Record Found."
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left currency-details sticky">
                  <div>
                    <a class="ellipsis body-2" @click="onGetTransaction(item.txid)">
                      <span>{{ helper.truncateString(item.txid, 30) }}</span>
                    </a>
                  </div>
                </td>
                <td class="text-left currency-details">
                  <span class="ellipsis body-2">
                    {{ item.block.mempool }}
                  </span>
                </td>
                <td class="text-left currency-details">
                  <span class="ellipsis body-2">
                    {{ helper.convertUnixTime(item.time) }}
                  </span>
                </td>
                <td>
                  <v-chip
                    label
                    :class="`body-2
                      ${ item.outputs.find((o) => o.address === search) ? 'secondary_3--text highlight--success': 'red--text highlight--failed'}
                    `"
                  >
                    {{ `${ item.outputs.find((o) => o.address === search) ? $t('Received') : $t('Sent') }` }}
                  </v-chip>
                </td>
                <td>
                  <span class="float-right">{{ convertAmount(item.fee, 8) }}</span>
                </td>
                <td class="text-right">
                  <v-chip
                    v-if="isAddress"
                    label
                    :class="` text-right
                      ${ item.outputs.find((x) => x.address === search) ? 'white--text secondary_3': 'white--text red'}
                    `"
                  >
                    {{ addOutputs(item.outputs) }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-card
        v-for="item in listTransactions"
        :key="item.txid"
        outlined
        class="common_background elevation-0 pa-2 mb-2 body-2"
      >
        <v-card-title class="pa-0 py-1">
          <h4 class="subtitle-1">
            {{ `${$t("Transaction")} ${$t("Details")}` }}
          </h4>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0 pa-sm-2">
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <p class="mb-0 body-2">
                <span class="float-left">{{ $t('Hash') }}:</span>
                <a class="float-right text-right ellipsis" @click="onGetTransaction(item.txid)">
                  <span class="ellipsis">{{ item.txid }}</span>
                </a>
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <p class="mb-0 text-right" >
                <span class="float-left">{{ $t('Block') }}:</span>
                <span class="float-right ellipsis secondary--text">{{ item.block.mempool }}</span>
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <p class="mb-0 text-right" >
                <span class="float-left">{{ $t('Date') }}:</span>
                <span class="float-right ellipsis secondary--text">{{ helper.convertUnixTime(item.time) }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" sm="6" >
              <div>
                <p class="mb-0">{{ $t('From') }}:</p>
                <p v-for="o in item.inputs" :key="o.address+ `${Math.random()}`" class="mb-0">
                  <a class="ml-2" @click="onGetBalance(o.address)">
                    <span class="float-left txn ellipsis">{{ o.address }}</span>
                  </a>
                  <span class="float-right ml-1 secondary--text">
                    {{ convertAmount(o.value, 8) }}
                  </span>
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div>
                <p class="mb-0">{{ $t('To') }}:</p>
                <p v-for="o in item.outputs" :key="o.address + `${Math.random()}`" class="mb-0">
                  <a class="ml-2" @click="onGetBalance(o.address)">
                    <span class="float-left txn ellipsis">{{ o.address }}</span>
                  </a>
                  <span class="float-right secondary--text">
                    {{ convertAmount(o.value, 8) }}
                  </span>
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" sm="6">
              <div>
                <p class="mb-0 mt-1">
                  <span class="float-left">{{ $t('Fee') }}:</span>
                  <span class="float-right secondary--text">{{ convertAmount(item.fee, 8) }}</span>
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-chip
                label
                :class="`float-right subtitle-1 body-2 white--text secondary_3`"
              >
                <span class="float-right">{{ addOutputs(item.outputs) }}</span>
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import helper from '@/utils/helper'

export default {
  props: {
    'list': { type: Array, default: () => [] },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
    'headers': { type: Array, default: () => [] },
  },
  data() {
    return {
      lib: require('@/utils/crypto/btc.js'),
      helper: helper,
      listTransactions: this.list,
      pageTransactions: 0,
      loadingTransactions: false,
      rowsPerPage: 100,
      selected_tab: { name: 'Transactions', value: 'transactions' },
      btnTabs: [
        { name: 'Transactions', value: 'transactions' },
      ],
    }
  },
  methods: {
    onPreviousPage() {
      if (this[`page${this.selected_tab.name}`] <= 1) {
        this[`page${this.selected_tab.name}`] = 0
      } else {
        this[`page${this.selected_tab.name}`] -= 1 * this.rowsPerPage
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`page${this.selected_tab.name}`] += 1 * this.rowsPerPage
      this.onPaginate()
    },
    async onPaginate() {
      const lib = new this.lib['Bitcoin']()

      this[`loading${this.selected_tab.name}`] = true

      const tempTxns = await lib.getTransactions({
        row: this.rowsPerPage,
        page: this[`page${this.selected_tab.name}`],
        address: this.search,
      })

      this.$set(this, 'listTransactions', tempTxns)

      this[`loading${this.selected_tab.name}`] = false
    },
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    convertAmount(amount, decimal = 8) {
      return (amount / 100000000).toLocaleString('en', { useGrouping: false, minimumFractionDigits: 8 })
    },
    addOutputs(items) {
      if (items) {
        let total = items.find((x) => x.address === this.search)

        if (total)
          return (total.value / 10000000).toLocaleString('en', {
            useGrouping: false,
            minimumFractionDigits: 8,
          })
        else {
          total = items.reduce((a, b) => {
            return a + b['value']
          }, 0)

          return (total / 100000000).toLocaleString('en', {
            useGrouping: false,
            minimumFractionDigits: 8,
          })
        }
      } else return 0
    },
  },
}
</script>

<style lang="scss" scoped>
  .highlight {
    min-width: 100px;
  }
</style>
