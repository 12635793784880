<template>
  <v-row>
    <v-col v-if="isAddress">
      <v-row>
        <v-col cols="6" md="3" lg="2">
          <v-btn-toggle
            class="fill-width d-flex v-btn-group v-btn-group--2"
            mandatory
            dense
          >
            <v-btn
              v-for="(item, idx) in btnTabs"
              :key="idx"
              :ripple="false"
              :value="item.value"
              class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
              @click="onChangeTab(item)"
            >
              {{ item.name }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          cols="6"
          md="3"
          offset-md="6"
          lg="2"
          offset-lg="8"
        >
          <v-row dense align="center">
            <v-col
              offset="8"
              cols="3"
              sm="2"
              lg="2"
            >
              <v-btn
                block
                :value="true"
                class="float-right no-hover-bg"
                @click="onPreviousPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
              </v-btn>
            </v-col>
            <v-col
              cols="3"
              sm="2"
              lg="2"
            >
              <v-btn
                block
                :value="false"
                class="float-right no-hover-bg"
                @click="onNextPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :items="listTransactions"
            :loading="loadingTransactions"
            :loader-height="1"
            :headers-length="6"
            no-data-text="No Record Found."
            hide-default-footer
            hide-default-header
            disable-pagination
            mobile-breakpoint="0"
          >
            <template v-slot:header="">
              <thead class="v-data-table-header">
                <tr>
                  <th width="15%" class="sticky">{{ $t('Hash') }} </th>
                  <th width="10%">{{ $t('Date') }} </th>
                  <th width="5%">{{ $t('Action') }}</th>
                  <th width="5%" class="text-center">{{ $t('Confirmed') }}</th>
                  <th width="10%" class="text-right">{{ $t('Amount') }}</th>
                </tr>
              </thead>
            </template>
            <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left currency-details sticky">
                  <div>
                    <a class="ellipsis body-2" @click="onGetTransaction(item.hash)">
                      <span>{{ helper.truncateString(item.hash, 30) }}</span>
                    </a>
                  </div>
                </td>
                <td class="text-left currency-details">
                  <span class="ellipsis body-2">
                    {{ helper.convertUnixTime(item.timestamp) }}
                  </span>
                </td>
                <td class="text-left currency-details">
                  <v-chip
                    label
                    :class="`body-2
                      ${ item.vin.find((o) => o.address === search) ? 'red--text highlight--failed' : 'secondary_3--text highlight--success'}
                    `"
                  >
                    {{ `${ item.vin.find((o) => o.address === search) ? $t('Sent') : $t('Received') }` }}
                  </v-chip>
                </td>
                <td class="text-center">
                  <v-icon v-if="item.isConfirmed" class="secondary_3--text">
                    mdi-checkbox-marked-outline
                  </v-icon>
                  <v-icon v-else class="red--text">
                    mdi-close-box-outline
                  </v-icon>
                </td>
                <td class="text-right">
                  <v-chip
                    v-if="isAddress"
                    label
                    :class="` text-right
                      ${ item.vout.find((x) => x.address === search) ? 'white--text secondary_3': 'white--text red'}
                    `"
                  >
                    {{ addOutputs(item.vout) }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-card
        v-for="item in listTransactions"
        :key="item.hash"
        outlined
        class="common_background elevation-0 pa-2 mb-2"
      >
        <v-row dense>
          <v-col cols="12" sm="6" class="flex-grow-1">
            <p class="mb-0 body-2">
              <span class="float-left">{{ $t('Hash') }}:</span>
              <a class="float-right text-right ellipsis" @click="onGetTransaction(item.hash)">
                {{ item.hash }}
              </a>
            </p>
          </v-col>
          <v-col cols="12" sm="3" class="flex-grow-0">
            <p class="mb-0">
              <span class="bold float-left">{{ $t('Block') }}:</span>
              <span class="float-right secondary--text">{{ item.blockHeight }}</span>
            </p>
          </v-col>
          <v-col cols="12" sm="3" class="flex-grow-0">
            <p class="mb-0">
              <span class="bold float-left">{{ $t('Date') }}:</span>
              <span class="float-right secondary--text">{{ helper.convertUnixTime(item.blockTimestamp) }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" >
            <div>
              <p class="mb-0 bold">{{ $t('From') }}</p>
              <p v-for="o in item.vin" :key="o.address" class="mb-0">
                <a v-if="o.address !== search" class="ml-2" @click="onGetBalance(o.address)">
                  <span class="float-left txn">{{ o.address }}</span>
                </a>
                <span v-else class="float-left txn">{{ o.address }}</span>
                <span class="float-right secondary--text">
                  {{ helper.convertToBigNumber(o.value, 8) }}
                </span>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>
              <p class="mb-0 bold">{{ $t('To') }}:</p>
              <p v-for="o in item.vout" :key="o.address" class="mb-0">
                <a v-if="o.address !== search" class="ml-2" @click="onGetBalance(o.address)">
                  <span class="float-left txn">{{ o.address }}</span>
                </a>
                <span v-else class="float-left txn">{{ o.address }}</span>
                <span class="float-right secondary--text">
                  {{ helper.convertToBigNumber(o.value, 8) }}
                </span>
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <div>
              <p class="mb-0 mt-2">
                <span class="float-left bold">{{ $t('Fee') }}:</span>
                <span class="float-right secondary--text">{{ item.fee }}</span>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>
              <v-chip
                label
                class="float-right subtitle-1 secondary_3 white--text"
              >
                <span>{{ addOutputs(item.vout) }}</span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import helper from '@/utils/helper'

export default {
  props: {
    'list': { type: Array, default: () => [] },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
    'headers': { type: Array, default: () => [] },
  },
  data() {
    return {
      lib: require('@/utils/crypto/ltc.js'),
      helper: helper,
      listTransactions: this.list,
      pageTransactions: 0,
      loadingTransactions: false,
      rowsPerPage: 100,
      selected_tab: { name: 'Transactions', value: 'transactions' },
      btnTabs: [
        { name: 'Transactions', value: 'transactions' },
      ],
    }
  },
  methods: {
    onPreviousPage() {
      if (this[`page${this.selected_tab.name}`] <= 1) {
        this[`page${this.selected_tab.name}`] = 0
      } else {
        this[`page${this.selected_tab.name}`] -= 1 * this.rowsPerPage
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`page${this.selected_tab.name}`] += 1 * this.rowsPerPage
      this.onPaginate()
    },
    async onPaginate() {
      const lib = new this.lib['Litecoin']()

      this[`loading${this.selected_tab.name}`] = true

      const tempTxns = await lib.getTransactions({
        row: this.rowsPerPage,
        page: this[`page${this.selected_tab.name}`],
        address: this.search,
      })

      this.$set(this, 'listTransactions', tempTxns)

      this[`loading${this.selected_tab.name}`] = false
    },
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    addOutputs(items) {

      if (items) {
        const total = items.filter((x) => x.address !== this.search).reduce((a, b) => {
          return a + b['value']
        }, 0)

        return helper.convertToBigNumber(total, 8)

      } else return 0
    },
  },
}
</script>
