class Polkadot {
  url = 'https://polkadot.subscan.io/api/'
  axios = require('axios')
  BigNumber = require('bignumber.js')
  WAValidator = require('multicoin-address-validator')
  instance = this.axios.create()

  axiosConfig(config = this.instance.defaults) {
    delete config.headers
    config = {
      headers: {
        'accept': '*/*',
        'content-type': 'application/json',
      },
    }

    return config
  }

  checkTxn(txn) {
    // return /^[a-fA-F0-9]{64}$/.test(txn)
    return true
  }

  checkAddress(addr) {
    return this.WAValidator.validate(addr, 'dot')
  }

  async getBalance(addr) {
    return await this.instance.post(
      `${this.url}v2/scan/search`,{
        key: addr,
        page: 0,
        row: 1,
      }, this.axiosConfig(),
    ).then(async (res) => {
      return res.data.data.account.balance
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransaction(txn) {
    return await this.instance.post(
      `${this.url}scan/extrinsic`, {
        'hash': txn,
      }, this.axiosConfig(),
    ).then((res) => {
      return res.data.data
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransactions(payload) {
    return await this.instance.post(
      `${this.url}scan/extrinsics`, {
        'row': payload.row,
        'page': payload.page,
        'address': payload.address,
      }, this.axiosConfig(),
    ).then((res) => {
      return res.data.data
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransfers(payload) {
    return await this.instance.post(
      `${this.url}scan/transfers`, {
        'row': payload.row,
        'page': payload.page,
        'address': payload.address,
      }, this.axiosConfig(),
    ).then((res) => {
      return res.data.data
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTokens(payload) {
    return []
  }

}

module.exports.Polkadot = Polkadot
