class Cardano {
  url = 'https://api.blockchair.com/cardano/raw/'
  axios = require('axios')
  BigNumber = require('bignumber.js')
  addrValidator = require('multicoin-address-validator')
  instance = this.axios.create()
  transactions = []

  axiosConfig(config = this.instance.defaults) {
    delete config.headers
    config = {
      headers: {
        'content-type': 'application/json',
      },
    }

    return config
  }

  checkTxn(txn) {
    return /^[a-fA-F0-9]{64}$/.test(txn)
  }

  checkAddress(addr) {
    return this.addrValidator.validate(addr, 'ADA')
  }

  async getBalance(addr) {
    return await this.instance.get(`${this.url}address/${addr}`, this.axiosConfig(),
    ).then(async (res) => {
      this.transactions = res.data.data[addr].address.caTxList.reverse()

      return res.data.data[addr].address.caBalance.getCoin / 1000000
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransaction(txn) {
    return await this.instance.get(
      `${this.url}transaction/${txn}`, this.axiosConfig(),
    ).then((res) => {
      return [res.data.data[txn].transaction]
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransactions(addr) {
    return this.transactions
  }

  async getTransfers(addr) {
    return []
  }

  async getTokens(addr) {
    return []
  }
}

module.exports.Cardano = Cardano
