<template>
  <v-row class="text-center" >
    <v-col>
      <v-progress-circular
        :width="3"
        :size="size"
        color="blue"
        indeterminate
        :style="`${height ? `height: ${ height }`: ''}`"
        class="progress--top"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    'size': { type: Number, default: 50 },
    'height': { type: String, default: '370px' },
  },
}
</script>
