class Vechain {
  url = 'https://explore-mainnet.veblocks.net/'
  axios = require('axios')
  BigNumber = require('bignumber.js')
  addrValidator = require('multicoin-address-validator')
  tokens = []
  instance = this.axios.create()

  axiosConfig(config = this.instance.defaults) {
    delete config.headers
    config = {
      headers: {
        'content-type': 'text/plain;charset=UTF-8',
      },
    }

    return config
  }

  checkTxn(txn) {
    return /^.{66}$/.test(txn)
  }

  checkAddress(addr) {
    return this.addrValidator.validate(addr, 'VET')
  }

  async getBalance(addr) {
    return await this.instance.get(
      `${this.url}accounts/${addr}`, this.axiosConfig(),
    ).then(async (res) => {
      this.tokens = res.data.tokens

      return (res.data.balance * 1) / 1e18
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransaction(txn) {
    return await this.instance.get(
      `${this.url}transactions/${txn}/receipt?head=`, this.axiosConfig(),
    ).then((res) => {
      return [res.data]
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransactions(payload) {
    return await this.instance.post(`${this.url}logs/transfer`, {
      criteriaSet: [
        { sender: payload.address },
        { recipient: payload.address },
      ],
      options: {
        'offset': payload.offset ? payload.offset : 0,
        'limit': payload.limit ? payload.limit : 50,
      },
      range: {
        from: 0,
        to: 4294967295,
        unit: 'block',
      },
      order: 'desc',
    }, this.axiosConfig(),
    ).then((res) => {
      return res.data
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransfers() {
    return []
  }

  async getTokens(payload) {
    return this.tokens
  }
}

module.exports.Vechain = Vechain
