<template>
  <div>
    <v-row v-if="listTokens.length > 0">
      <v-col>
        <v-card
          class="common_background elevation-0 pa-0 pa-sm-2 mb-2 body-2"
        >
          <v-row>
            <v-col><h3 class="my-3 bold pl-2">Tokens</h3></v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-for="token in listTokens.slice(0, tokenSlice)"
              :key="token.key"
              cols="6"
              sm="4"
              md="3"
              lg="2"
            >
              <v-card
                class="common_background elevation-0 pa-2"
              >
                <div class="adjust-center">
                  <img
                    v-if="token.metadata"
                    class="token-img mr-1"
                    :lazy-src="helper.extractEosWaxTokenImgUrl(token.metadata.logo)"
                    :src="helper.extractEosWaxTokenImgUrl(token.metadata.logo)"
                    @error="helper.imgerror"
                  />
                  <span>{{ token.contract }}</span>
                </div>
                <p class="subtitle-2 mb-0 caption">
                  <span class=" bold">{{ token.amount }} {{ token.currency }}</span>
                </p>
                <p class="subtitle-2 mb-0 caption">
                  <span class="secondary_3--text"> = ${{ token.usd_value ? token.usd_value : 0 }}</span>
                </p>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn large class="primary uncapitalize body-2 px-4" @click="onLoadMoreTokens()">
                <v-icon>mdi-refresh</v-icon>
                <span class="">{{ $t('LoadMore') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isAddress">
      <v-col cols="8" md="3" lg="2">
        <v-btn-toggle
          class="fill-width d-flex v-btn-group v-btn-group--2"
          mandatory
          dense
        >
          <v-btn
            v-for="(item, idx) in btnTabs"
            :key="idx"
            :ripple="false"
            :value="item.value"
            class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
            @click="onChangeTab(item)"
          >
            {{ item.name }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        cols="4"
        md="3"
        offset-md="6"
        lg="2"
        offset-lg="8"
      >
        <v-row dense align="center">
          <v-col
            cols="6"
            sm="3"
            offset-sm="6"
          >
            <v-btn
              block
              :value="true"
              :disabled="isDisablePagination"
              class="float-right no-hover-bg"
              @click="onPreviousPage"
            >
              <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            sm="3"
          >
            <v-btn
              block
              :value="false"
              :disabled="isDisablePagination"
              class="float-right no-hover-bg"
              @click="onNextPage"
            >
              <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isAddress" cols="12">
        <v-data-table
          v-if="selected_tab.value === 'transactions'"
          :items="listTransactions"
          :loading="loadingTransactions"
          :loader-height="1"
          :headers-length="8"
          no-data-text="No Record Found."
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
          hide-default-header
        >
          <template v-slot:header="">
            <thead class="v-data-table-header">
              <tr>
                <th width="15%" class="sticky">{{ $t('Hash') }} </th>
                <th width="10%">{{ $t('Block') }} </th>
                <th width="10%">{{ $t('Date') }} </th>
                <th width="5%">{{ $t('Action') }}</th>
                <th width="25%"></th>
                <th width="10%" class="text-right">{{ $t('Amount') }}</th>
              </tr>
            </thead>
          </template>
          <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
          <template v-slot:item="{ item }">
            <tr>
              <td class="sticky">
                <div>
                  <a class="ellipsis body-2" @click="onGetTransaction(item.action_trace.trx_id)">
                    <span>{{ helper.truncateString(item.action_trace.trx_id, 30) }}</span>
                  </a>
                </div>
              </td>
              <td class="text-left currency-details">
                {{ item.block_num }}
              </td>
              <td class="text-left currency-details">
                <span class="ellipsis body-2">
                  {{ helper.utcDate(new Date(item.block_time)) }}
                </span>
              </td>
              <td v-html="onGetTransferLabel(item.action_trace.act)"></td>
              <td>
                <div v-if="item.action_trace.act.name === 'transfer'" class="d-inline-flex">
                  <a class="ellipsis body-2" @click="onGetBalance(item.action_trace.act.data.from)">
                    <span>{{ item.action_trace.act.data.from }}</span>
                  </a>
                  <v-icon class="subtitle-1">mdi-arrow-right</v-icon>
                  <a class="ellipsis body-2" @click="onGetBalance(item.action_trace.act.data.to)">
                    <span>{{ item.action_trace.act.data.to }}</span>
                  </a>
                </div>
                <div v-else-if="item.action_trace.act.name === 'signup'">
                  <p class="mb-0 body-2">
                    <span>owner: </span>
                    <a class="ellipsis" @click="onGetBalance(item.action_trace.act.data.owner)">
                      <span>{{ item.action_trace.act.data.owner }}</span>
                    </a>
                  </p>
                  <p class="mb-0 body-2">
                    <span>referral: </span>
                    <a class="ellipsis" @click="onGetBalance(item.action_trace.act.data.referral)">
                      <span>{{ item.action_trace.act.data.referral }}</span>
                    </a>
                  </p>
                </div>
                <div v-else-if="item.action_trace.act.name === 'open'">
                  <p class="mb-0 body-2">
                    <span>owner: </span>
                    <a class="ellipsis " @click="onGetBalance(item.action_trace.act.data.owner)">
                      <span>{{ item.action_trace.act.data.owner }}</span>
                    </a>
                  </p>
                  <p class="mb-0 body-2">
                    <span>ram_payer: </span>
                    <a class="ellipsis" @click="onGetBalance(item.action_trace.act.data.referral)">
                      <span>{{ item.action_trace.act.data.ram_payer }}</span>
                    </a>
                  </p>
                  <p class="mb-0">
                    <span>symbol: </span>
                    <span>{{ item.action_trace.act.data.symbol }}</span>
                  </p>
                </div>
                <div v-else-if="item.action_trace.act.name === 'logpowerup'">
                  <p class="mb-0 body-2">
                    <span>action: </span><span>{{ item.action_trace.act.data.action }}</span>
                  </p>
                  <p class="mb-0 body-2">
                    <span>cost: </span><span>{{ item.action_trace.act.data.cost }}</span>
                  </p>
                  <p class="mb-0 body-2">
                    <span>fee: </span><span>{{ item.action_trace.act.data.fee }}</span>
                  </p>
                  <p class="mb-0 body-2">
                    <span>payer: </span>
                    <a class="ellipsis body-2" @click="onGetBalance(item.action_trace.act.data.payer)">
                      <span>{{ item.action_trace.act.data.payer }}</span>
                    </a>
                  </p>
                  <p class="mb-0 body-2">
                    <span>received_cpu_ms: </span><span>{{ item.action_trace.act.data.received_cpu_ms }}</span>
                  </p>
                  <p class="mb-0 body-2">
                    <span>received_net_kb: </span><span>{{ item.action_trace.act.data.received_net_kb }}</span>
                  </p>
                  <p class="mb-0 body-2">
                    <span>receiver: </span>
                    <a class="ellipsis body-2" @click="onGetBalance(item.action_trace.act.data.receiver)">
                      <span>{{ item.action_trace.act.data.receiver }}</span>
                    </a>
                  </p>
                  <p class="mb-0 body-2">
                    <span>total_billed: </span><span>{{ item.action_trace.act.data.total_billed }}</span>
                  </p>
                  <p class="mb-0 body-2">
                    <span>message: </span><span>{{ item.action_trace.act.data.message }}</span>
                  </p>
                  <p class="mb-0 body-2">
                    <span>symbol: </span>
                    <a class="ellipsis body-2" @click="onGetBalance(item.action_trace.act.data.referral)">
                      <span>{{ item.action_trace.act.data.symbol }}</span>
                    </a>
                  </p>
                </div>
                <div v-else-if="item.action_trace.act.name === 'claimrewards'">
                  <p class="mb-0 body-2">
                    <a class="ellipsis mr-1" @click="onGetBalance(item.action_trace.act.data.owner)">
                      <span>{{ item.action_trace.act.data.owner }}</span>
                    </a>
                    <span>claim block producer rewards</span>
                  </p>
                </div>
                <div v-else-if="item.action_trace.act.name === 'mine'">
                  <p class="mb-0 body-2">
                    <span>executor: </span><span>{{ item.action_trace.act.data.executor }}</span>
                  </p>
                  <p class="mb-0 body-2">
                    <span>nonce: </span><span>{{ item.action_trace.act.data.nonce }}</span>
                  </p>
                </div>
                <div v-else-if="item.action_trace.act.name === 'setcode'">
                  <p class="mb-0 body-2"><span>Code updated.</span></p>
                </div>
                <div v-else-if="item.action_trace.act.name === 'approve'">
                  <span class="body-2">
                    {{
                      `
                        ${item.action_trace.act.data.proposer}
                        approved the proposal
                        ${ item.action_trace.act.data.proposal_name }
                        from
                        ${ item.action_trace.act.data.proposer }
                      `
                    }}
                  </span>
                </div>
                <div v-else-if="item.action_trace.act.name === 'propose'">
                  <span class="body-2">
                    {{
                      `
                        ${item.action_trace.act.data.proposer}
                        proposed multi-sig
                        ${ item.action_trace.act.data.proposal_name }
                        with ${ item.action_trace.act.data.requested.length}
                        requested approvals.
                      `
                    }}
                  </span>
                </div>
                <div v-else-if="item.action_trace.act.name === 'cancel'">
                  <span class="body-2">
                    {{
                      `
                        ${item.action_trace.act.data.proposer}
                        cancelled the proposal
                        ${ item.action_trace.act.data.proposal_name }
                        from
                        ${ item.action_trace.act.data.proposer }
                      `
                    }}
                  </span>
                </div>
                <div v-else-if="item.action_trace.act.name === 'claimdropkey'">
                  <div
                    v-for="key of Object.keys(item.action_trace.act.data)"
                    :key="key"
                    class="mb-0"
                  >
                    <span>{{ key }}: </span>
                    <span>{{ item.action_trace.act.data[key] }}</span>
                  </div>
                </div>
                <div v-else-if="item.action_trace.act.name === 'assertdrop'">
                  <div
                    v-for="key of Object.keys(item.action_trace.act.data)"
                    :key="key"
                    class="mb-0"
                  >
                    <span>{{ key }}: </span>
                    <span>{{ item.action_trace.act.data[key] }}</span>
                  </div>
                </div>
                <div v-else-if="item.action_trace.act.name === 'updateauth'">
                  {{
                    `${ item.action_trace.act.data.account }
                    set permission ${ item.action_trace.act.data.permission }
                    with the parent permission ${ item.action_trace.act.data.parent }
                    to have the authentication
                    `
                  }}
                </div>
              </td>
              <td class="text-right">
                <v-chip
                  v-if="item.action_trace.act.data !== null && item.action_trace.act.data.quantity"
                  label
                  class="text-right white--text secondary_3"
                >
                  {{ item.action_trace.act.data.quantity }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table
          v-if="selected_tab.value === 'nfts'"
          :items="listNfts"
          :loading="loadingNfts"
          :loader-height="1"
          :headers-length="8"
          no-data-text="No Record Found."
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
          hide-default-header
        >
          <template v-slot:header="">
            <thead class="v-data-table-header">
              <tr>
                <th width="40%" class="sticky">{{ $t('Name') }} </th>
                <th width="10%">{{ $t('ID') }} </th>
                <th width="10%">{{ $t('Collection') }} </th>
                <th width="20%">{{ $t('Author') }}</th>
                <th width="20%">{{ `${$t('Serial')} / ${$t('EditionSize')}` }}</th>
              </tr>
            </thead>
          </template>
          <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <div class="d-inline-flex">
                  <div class="my-auto">
                    <img
                      :alt="findVal(item, 'name')"
                      :lazy-src="getImagePath(item)"
                      :src="getImagePath(item)"
                      class="currency-logo"
                      @error="helper.imgerror"
                    />
                  </div>
                  <div class="ml-2 my-auto">
                    <span>{{ findVal(item, 'name') }}</span>
                  </div>
                </div>
              </td>
              <td>{{ item.assetId ? item.assetId : item.asset_id }}</td>
              <td>{{ item.category ? item.category : item.collection.collection_name }}</td>
              <td>{{ item.author ? item.author : item.collection.author }}</td>
              <td>
                {{
                  item.extra ? `${ item.extra.mintNumber ? item.extra.mintNumber : '-' } / ${item.extra.totalMinted}` :
                  `${item.template_mint} / ${ item.template.issued_supply }`
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-else>
        <v-card
          v-for="trx in listTransactions"
          :key="`${trx.id}-${Math.random()}`"
          class="common_background elevation-0 pa-2 mb-2 body-2"
        >
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <p class="mb-0 body-2">
                <span class="float-left">{{ $t('Hash') }}:</span>
                <span class="float-right secondary--text ellipsis">{{ trx.id }}</span>
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <p v-if="trx.block_num" class="mb-0 text-right" >
                <span class="float-left">{{ $t('Block') }}:</span>
                <span class="float-right ellipsis secondary--text">{{ trx.block_num }}</span>
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <p class="mb-0 text-right" >
                <span class="float-left">{{ $t('Date') }}:</span>
                <span class="float-right ellipsis secondary--text">{{ helper.utcDate(new Date(trx.block_time)) }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3" lg="2">
              <v-btn-toggle
                v-model="selected_action.value"
                class="fill-width d-flex v-btn-group v-btn-group--2"
                mandatory
                dense
              >
                <v-btn
                  v-for="(action, idx) in btnActions"
                  :key="idx"
                  :ripple="false"
                  :value="action.value"
                  class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
                  @click="onChangeTabAction(action)"
                >
                  {{ action.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="selected_action.value === 'actions'">
              <v-data-table
                :items="trx.trx.trx.actions"
                :headers-length="5"
                class="no-background"
                no-data-text="No Record Found."
                hide-default-footer
                hide-default-header
                disable-pagination
                mobile-breakpoint="0"
              >
                <template v-slot:header="">
                  <thead class="v-data-table-header">
                    <tr>
                      <th width="25%" class="sticky">{{ $t('Contract') }} </th>
                      <th width="25%">{{ $t('Name') }} </th>
                      <th width="25%">{{ $t('Authorization') }} </th>
                      <th width="25%">{{ $t('Data') }} </th>
                    </tr>
                  </thead>
                </template>
                <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-left currency-details sticky">
                      {{ item.account }}
                    </td>
                    <td v-html="onGetTransferLabel(item)"></td>
                    <td>
                      <p
                        v-for="(auth, idx) in item.authorization"
                        :key="idx"
                        class="mb-0"
                      >
                        <span>{{ auth.actor }}:</span> <span>{{ auth.permission }}</span>
                      </p>
                    </td>
                    <td>
                      <div
                        v-for="key of Object.keys(item.data)"
                        :key="key"
                        class="mb-0"
                      >
                        <span>{{ key }}: </span>
                        <span>{{ item.data[key] }}</span>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col v-else>
              <v-data-table
                :items="trx.traces"
                :headers-length="5"
                class="no-background"
                no-data-text="No Record Found."
                hide-default-footer
                hide-default-header
                disable-pagination
                mobile-breakpoint="0"
              >
                <template v-slot:header="">
                  <thead class="v-data-table-header">
                    <tr>
                      <th width="25%" class="sticky">{{ $t('Contract') }} </th>
                      <th width="25%">{{ $t('Name') }} </th>
                      <th width="25%">{{ $t('Data') }} </th>
                      <th width="25%" class="text-right">{{ $t('Amount') }} </th>
                    </tr>
                  </thead>
                </template>
                <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-left currency-details sticky">
                      {{ item.act.account }}
                    </td>
                    <td v-html="onGetTransferLabel(item.act)"></td>
                    <td>
                      <div v-if="item.act.name === 'transfer'">
                        <a class="ellipsis body-2" @click="onGetBalance(item.act.data.from)">
                          <span>{{ item.act.data.from }}</span>
                        </a>
                        <v-icon class="subtitle-1">mdi-arrow-right</v-icon>
                        <a class="ellipsis body-2" @click="onGetBalance(item.act.data.to)">
                          <span>{{ item.act.data.to }}</span>
                        </a>
                      </div>
                      <div v-else-if="item.act.name === 'signup'">
                        <p class="mb-0 body-2">
                          <span>owner: </span>
                          <a class="ellipsis" @click="onGetBalance(item.act.data.owner)">
                            <span>{{ item.act.data.owner }}</span>
                          </a>
                        </p>
                        <p class="mb-0 body-2">
                          <span>referral: </span>
                          <a class="ellipsis" @click="onGetBalance(item.act.data.referral)">
                            <span>{{ item.act.data.referral }}</span>
                          </a>
                        </p>
                      </div>
                      <div v-else-if="item.act.name === 'open'">
                        <p class="mb-0 body-2">
                          <span>owner: </span>
                          <a class="ellipsis " @click="onGetBalance(item.act.data.owner)">
                            <span>{{ item.act.data.owner }}</span>
                          </a>
                        </p>
                        <p class="mb-0 body-2">
                          <span>ram_payer: </span>
                          <a class="ellipsis" @click="onGetBalance(item.act.data.referral)">
                            <span>{{ item.act.data.ram_payer }}</span>
                          </a>
                        </p>
                        <p class="mb-0">
                          <span>symbol: </span>
                          <span>{{ item.act.data.symbol }}</span>
                        </p>
                      </div>
                      <div v-else-if="item.act.name === 'logpowerup'">
                        <p class="mb-0 body-2">
                          <span>action: </span><span>{{ item.act.data.action }}</span>
                        </p>
                        <p class="mb-0 body-2">
                          <span>cost: </span><span>{{ item.act.data.cost }}</span>
                        </p>
                        <p class="mb-0 body-2">
                          <span>fee: </span><span>{{ item.act.data.fee }}</span>
                        </p>
                        <p class="mb-0 body-2">
                          <span>payer: </span>
                          <a class="ellipsis body-2" @click="onGetBalance(item.act.data.payer)">
                            <span>{{ item.act.data.payer }}</span>
                          </a>
                        </p>
                        <p class="mb-0 body-2">
                          <span>received_cpu_ms: </span><span>{{ item.act.data.received_cpu_ms }}</span>
                        </p>
                        <p class="mb-0 body-2">
                          <span>received_net_kb: </span><span>{{ item.act.data.received_net_kb }}</span>
                        </p>
                        <p class="mb-0 body-2">
                          <span>receiver: </span>
                          <a class="ellipsis body-2" @click="onGetBalance(item.act.data.receiver)">
                            <span>{{ item.act.data.receiver }}</span>
                          </a>
                        </p>
                        <p class="mb-0 body-2">
                          <span>total_billed: </span><span>{{ item.act.data.total_billed }}</span>
                        </p>
                        <p class="mb-0 body-2">
                          <span>message: </span><span>{{ item.act.data.message }}</span>
                        </p>
                        <p class="mb-0 body-2">
                          <span>symbol: </span>
                          <a class="ellipsis body-2" @click="onGetBalance(item.act.data.referral)">
                            <span>{{ item.act.data.symbol }}</span>
                          </a>
                        </p>
                      </div>
                      <div v-else-if="item.act.name === 'claimrewards'">
                        <p class="mb-0 body-2">
                          <a class="ellipsis mr-1" @click="onGetBalance(item.act.data.owner)">
                            <span>{{ item.act.data.owner }}</span>
                          </a>
                          <span>claim block producer rewards</span>
                        </p>
                      </div>
                      <div v-else-if="item.act.name === 'mine'">
                        <p class="mb-0 body-2">
                          <span>executor: </span>
                          <a class="ellipsis body-2" @click="onGetBalance(item.act.data.executor)">
                            <span>{{ item.act.data.executor }}</span>
                          </a>
                        </p>
                        <p class="mb-0 body-2">
                          <span>nonce: </span><span>{{ item.act.data.nonce }}</span>
                        </p>
                      </div>
                      <div v-else-if="item.act.name === 'setcode'">
                        <p class="mb-0 body-2"><span>Code updated.</span></p>
                      </div>
                      <div v-else-if="item.act.name === 'approve'">
                        <span class="body-2">
                          {{
                            `
                              ${item.act.data.proposer}
                              approved the proposal
                              ${ item.act.data.proposal_name }
                              from
                              ${ item.act.data.proposer }
                            `
                          }}
                        </span>
                      </div>
                      <div v-else-if="item.act.name === 'propose'">
                        <span class="body-2">
                          {{
                            `
                              ${item.act.data.proposer}
                              proposed multi-sig
                              ${ item.act.data.proposal_name }
                              with ${ item.act.data.requested.length}
                              requested approvals.
                            `
                          }}
                        </span>
                      </div>
                      <div v-else-if="item.act.name === 'cancel'">
                        <span class="body-2">
                          {{
                            `
                              ${item.act.data.proposer}
                              cancelled the proposal
                              ${ item.act.data.proposal_name }
                              from
                              ${ item.act.data.proposer }
                            `
                          }}
                        </span>
                      </div>
                      <div v-else-if="item.act.name === 'claimdropkey' ||item.act.name === 'pushlog'">
                        <div
                          v-for="key of Object.keys(item.act.data)"
                          :key="key"
                          class="mb-0"
                        >
                          <span>{{ key }}: </span>
                          <span>{{ item.act.data[key] }}</span>
                        </div>
                      </div>
                      <div v-else-if="item.act.name === 'assertdrop'">
                        <div
                          v-for="key of Object.keys(item.act.data)"
                          :key="key"
                          class="mb-0"
                        >
                          <span>{{ key }}: </span>
                          <span>{{ item.act.data[key] }}</span>
                        </div>
                      </div>
                      <div v-else-if="item.act.name === 'updateauth'">
                        {{
                          `${ item.act.data.account }
                          set permission ${ item.act.data.permission }
                          with the parent permission ${ item.act.data.parent }
                          to have the authentication
                          `
                        }}
                      </div>
                      <div v-else-if="item.act.name === 'issue'">
                        <a class="ellipsis body-2" @click="onGetBalance(item.act.account)">
                          <span>{{ item.act.account }}</span>
                        </a>
                        {{
                          `issued ${ item.act.data.quantity } to`
                        }}
                        <a class="ellipsis body-2" @click="onGetBalance(item.act.data.to)">
                          <span>{{ item.act.data.to }}</span>
                        </a>
                      </div>
                    </td>
                    <td class="text-right">
                      <v-chip
                        v-if="item.act.data !== null && item.act.data.quantity"
                        label
                        class="text-right white--text secondary_3"
                      >
                        {{ item.act.data.quantity }}
                      </v-chip>
                    </td>
                    <!-- <td>
                      <p
                        v-for="(auth, idx) in item.authorization"
                        :key="idx"
                        class="mb-0"
                      >
                        <span>{{ auth.actor }}:</span> <span>{{ auth.permission }}</span>
                      </p>
                    </td>
                    <td>
                      <div
                        v-for="key of Object.keys(item.data)"
                        :key="key"
                        class="mb-0"
                      >
                        <span>{{ key }}: </span>
                        <span>{{ item.data[key] }}</span>
                      </div>
                    </td> -->
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import helper from '@/utils/helper'

export default {
  props: {
    'list': { type: Array, default: () => [] },
    'tokens': { type: Array, default: () => [] },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
    'currency': { type: String, default: () => '' },
  },
  data() {
    return {
      ld:  _,
      lib: require(`@/utils/crypto/${this.currency.toLowerCase()}.js`),
      helper: helper,
      listTransactions: this.isAddress ? this.removeDuplicateTransaction(this.list) : this.list,
      listTokens: this.removeZeroPrices(this.tokens),
      listNfts: [],
      pageTransactions: 0,
      isDisablePagination: false,
      loadingTransactions: false,
      loadingNfts: false,
      rowsPerPage: 100,
      tokenSlice: 12,
      position: -1,
      selected_tab: { name: 'Transactions', value: 'transactions' },
      btnTabs: [
        { name: 'Transactions', value: 'transactions' },
        { name: 'NFTs', value: 'nfts' },
      ],
      selected_action: { name: 'Actions', value: 'actions' },
      btnActions: [
        { name: 'Actions', value: 'actions' },
        { name: 'Traces', value: 'traces' },
      ],
    }
  },
  mounted() {
    this.position = (this.listTransactions[0].account_action_seq + 1)
  },
  methods: {
    getImagePath(item) {
      const path = this.findVal(item, 'img')

      return path.substr(0, 4) === 'http' ? path : `https://ipfs.io/ipfs/${path}`
    },
    findVal(object, key) {
      let value

      Object.keys(object).some((k) => {
        if (k === key) {
          value = object[k]

          return true
        }

        if (object[k] && typeof object[k] === 'object') {
          value = this.findVal(object[k], key)

          return value !== undefined
        }

        return false
      })

      return value
    },
    async onChangeTab(item) {
      this.selected_tab = item
      this.isDisablePagination = item.value === 'nfts' ? true : false
      if (item.value === 'nfts' && this.listNfts.length < 1) {
        this.loadingNfts = true
        const lib = new this.lib[helper.capitalizeFirstLetter(this.currency)]()
        const temp = await lib.getNfts({ address: this.search })

        this.$set(this, 'listNfts', temp)
        this.loadingNfts = false
      }
    },
    onLoadMoreTokens() {
      this.tokenSlice += 12
    },
    onPreviousPage() {
      if (this[`page${this.selected_tab.name}`] <= 1) {
        this[`page${this.selected_tab.name}`] = 0
      } else {
        this[`page${this.selected_tab.name}`] -= 1 * this.rowsPerPage
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`page${this.selected_tab.name}`] += 1 * this.rowsPerPage
      this.onPaginate()
    },
    async onPaginate() {
      const lib = new this.lib[helper.capitalizeFirstLetter(this.currency)]()

      this[`loading${this.selected_tab.name}`] = true

      const tempTxns = await lib.getTransactions({
        row: this.rowsPerPage,
        page: this[`page${this.selected_tab.name}`],
        address: this.search,
        pos: this.position - this.rowsPerPage,
        offset: this.rowsPerPage * -1,
        new_req: true,
      })

      this.listTransactions = tempTxns

      this[`loading${this.selected_tab.name}`] = false
    },
    onChangeTabAction(item) {
      this.selected_action = item
    },
    removeZeroPrices(arr) {
      const x = arr.filter((item, index) => {
        return item.amount > 0
      })

      return x
    },
    addTotal(exchanges, prop) {
      const priceCount = exchanges.reduce((a,b) => a += b[prop], 0)

      return priceCount
    },
    onGetTransferLabel(item) {
      let labelText = ''
      let labelColor = ''
      const itemLabel = item

      if (itemLabel.name === 'transfer') {
        const label = itemLabel.account.split('.')

        if (label.length === 2) {
          labelColor = 'secondary_3--text highlight--success'
          if (label[0] === 'eosio' && label[1] === 'vpay') labelText = 'Vote Pay'
          else if (label[0] === 'eosio' && label[1] === 'bpay') labelText = 'Block Pay'
          else if (label[0] === 'eosio' && label[1] === 'token' && itemLabel.data.to === this.search) labelText = 'Received Token'
          else if (label[0] === 'invite' && label[1] === 'chain' && itemLabel.data.to === this.search) labelText = 'Received Token'
          else {
            if (itemLabel.data.to === this.search) {
              labelColor = 'secondary_3--text highlight--success'
              labelText = 'Received Token'
            } else {
              labelColor = 'red--text highlight--failed'
              labelText = 'Send Token'
            }
          }
        } else {
          if (itemLabel.data.to === this.search) {
            labelColor = 'secondary_3--text highlight--success'
            labelText = 'Received Token'
          } else {
            labelColor = 'red--text highlight--failed'
            labelText = 'Send Token'
          }
        }
      } else if (itemLabel.name === 'claimrewards') {
        labelText = 'Claim Rewards'
        labelColor = 'highlight--rewards'
      } else if (itemLabel.name === 'mine') {
        labelText = `${itemLabel.account}-${itemLabel.name}`
        labelColor = 'highlight--mine'
      } else if (itemLabel.name === 'setcode') {
        labelText = 'Set Code'
        labelColor = 'highlight--setcode'
      } else if (itemLabel.name === 'approve' ||
        itemLabel.name === 'propose' ||
        itemLabel.name === 'cancel' ||
        itemLabel.name === 'updateauth'
      ) {
        labelText = `${
          itemLabel.name === 'approve' ?
            'Approve Proposal' : itemLabel.name === 'propose' ?
              'Propose Proposal' : itemLabel.name === 'cancel' ?
                'Cancel Proposal' : 'Update Auth' }`
        labelColor = 'highlight--proposal'
      } else if (itemLabel.name === 'signup') {
        labelText = `${itemLabel.account}-${itemLabel.name}`
        labelColor = 'highlight--mine'
      } else if (itemLabel.name === 'logpowerup' ||
        itemLabel.name === 'open' ||
        itemLabel.name === 'logmint' ||
        itemLabel.name === 'claimdropkey' ||
        itemLabel.name === 'assertdrop' ||
        itemLabel.name === 'claim' ||
        itemLabel.name === 'issue' ||
        itemLabel.name === 'pushlog' ||
        itemLabel.name === 'noop'
      ) {
        labelText = `${itemLabel.account}-${itemLabel.name}`
        labelColor = 'highlight--mine'
      }

      return `
        <span class="v-chip v-chip--label v-size--default ${labelColor ? labelColor : ''} ">
          <span class="v-chip__content body-2"> ${ labelText ? labelText : '' } </span>
        </span>
      `
    },
    removeDuplicateTraces(arr) {
      return arr.filter((item, index) => {
        const flag1 = JSON.stringify(item.act)
        const flag2 = item.trx_id

        return index === arr.findIndex((obj) => {
          return JSON.stringify(obj.act) === flag1 && obj.trx_id === flag2
        })
      })
    },
    removeDuplicateTransaction(arr) {
      return arr.filter((item, index) => {
        const flag1 = JSON.stringify(item.action_trace.act)
        const flag2 = item.action_trace.trx_id

        return index === arr.findIndex((obj) => {
          return JSON.stringify(obj.action_trace.act) === flag1 && obj.action_trace.trx_id === flag2
        })
      })
    },
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    convertDate(date) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
      const test = helper.convertDateToTimeZone(date, tz)

      return test.toLocaleTimeString()
    },
  },
}

</script>

<style lang="scss" scoped>
.test {
  border: 0;
}
</style>
