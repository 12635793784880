<template>
  <v-row>
    <v-col v-if="isAddress">
      <v-data-table
        :items="list"
        loading-text=""
        :headers="headers"
        :headers-length="5"
        hide-default-footer
        disable-pagination
        mobile-breakpoint="0"
      >
        <template slot="no-data" class="text-left text-sm-center">{{ $t('NoTransactionFound') }}</template>
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-left currency-details sticky">
              <div>
                <a class="ellipsis body-2" @click="onGetTransaction(item.ctbId ? item.ctbId : item.ctsI)">
                  {{ item.ctbId ? item.ctbId : item.ctsId }}
                </a>
              </div>
            </td>
            <td class="text-left currency-details">
              <span class="ellipsis body-2">
                {{ item.block }}
              </span>
            </td>
            <td class="text-left currency-details">
              <span class="ellipsis body-2">
                {{ helper.convertUnixTime(item.ctbTimeIssued ? item.ctbTimeIssued : item.ctsTxTimeIssued, true) }}
              </span>
            </td>
            <td>
              <v-chip
                v-if="isAddress"
                label
                :class="`body-2
                  ${ item.ctbOutputs.find((x) => x.ctaAddress === search) ? 'secondary_3--text highlight--success': 'red--text highlight--failed'}
                `"
              >
                {{ `${ item.ctbOutputs.find((x) => x.ctaAddress === search) ? $t('Received') : $t('Sent') }` }}
              </v-chip>
            </td>
            <td>
              <span class="float-right">{{ convertAmount(item.ctbFees.getCoin) }}</span>
            </td>
            <td class="text-right">
              <v-chip
                v-if="isAddress"
                label
                :class="` text-right
                  ${ item.ctbOutputs.find((x) => x.ctaAddress === search) ? 'white--text secondary_3': 'white--text red'}
                `"
              >
                {{ onFindADAValue(item.ctbInputs, item.ctbOutputs) }}
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-else>
      <v-card
        v-for="item in list"
        :key="`${ item.ctbId }-${Math.random()}`"
        outlined
        class="common_background elevation-0 pa-2 mb-2 body-2"
      >
        <v-card-title class="pa-0 py-1">
          <h4 class="subtitle-1">
            {{ `${$t("Transaction")} ${$t("Details")}` }}
          </h4>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0 pa-sm-2">
          <v-row dense class="my-2">
            <v-col cols="12" sm="6" class="flex-grow-1">
              <p class="mb-0 body-2">
                <span class="float-left ">{{ $t('Hash') }}:</span>
                <a class="float-right text-right ellipsis" @click="onGetTransaction(item.ctbId ? item.ctbId : item.ctsId)">
                  {{ item.ctbId ? item.ctbId : item.ctsId }}
                </a>
              </p>
            </v-col>
            <v-col cols="12" sm="6">
              <p class="mb-0 text-right body-2" >
                <span class="float-left">{{ $t('Date') }}:</span>
                <span class="float-right ellipsis secondary--text">
                  {{ helper.convertUnixTime(item.ctbTimeIssued ? item.ctbTimeIssued : item.ctsTxTimeIssued, true) }}
                </span>
              </p>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" sm="6" >
              <div>
                <p class="mb-0 body-2">{{ $t('From') }}:</p>
                <p v-for="o in item.ctbInputs ? item.ctbInputs : item.ctsInputs" :key="o.ctaAddress+ `${Math.random()}`" class="mb-0 body-2">
                  <a v-if="o.ctaAddress !== search" class="ml-2" @click="onGetBalance(o.ctaAddress)">
                    <span class="float-left txn ellipsis">{{ o.ctaAddress }}</span>
                  </a>
                  <span v-else class="float-left ellipsis">{{ o.ctaAddress }}</span>
                  <span class="float-right ml-1 secondary--text">
                    {{ convertAmount(o.ctaAmount.getCoin) }}
                  </span>
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="6" >
              <div>
                <p class="mb-0 body-2">{{ $t('To') }}:</p>
                <p v-for="o in item.ctbOutputs ? item.ctbOutputs : item.ctsOutputs" :key="o.ctaAddress+ `${Math.random()}`" class="mb-0 body-2">
                  <a v-if="o.ctaAddress !== search" class="ml-2" @click="onGetBalance(o.ctaAddress)">
                    <span class="float-left txn ellipsis">{{ o.ctaAddress }}</span>
                  </a>
                  <span v-else class="float-left ellipsis">{{ o.ctaAddress }}</span>
                  <span class="float-right ml-1 secondary--text">
                    {{ convertAmount(o.ctaAmount.getCoin) }}
                  </span>
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" sm="6">
              <div>
                <p v-if="isAddress" class="mb-0 mt-1 body-2" >
                  <span class="float-left ">{{ $t('Fee') }}:</span>
                  <span v-if="isAddress" class="float-right">{{ convertAmount(item.ctbFees.getCoin) }}</span>
                  <span v-else class="float-right">{{ convertAmount(item.ctbFees.getCoin) }}</span>
                </p>
                <p v-else class="mb-0 mt-1 body-2" >
                  <span class="float-left ">{{ $t('Fee') }}:</span>
                  <span class="float-right secondary--text">{{ convertAmount(item.ctsFees.getCoin) }}</span>
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-chip
                v-if="!isAddress && item.ctsTotalOutput"
                label
                :class="`float-right subtitle-1 body-2 white--text secondary_3`"
              >
                <span class="float-right">{{ convertAmount(item.ctsTotalOutput.getCoin) }}</span>
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import helper from '@/utils/helper'

export default {
  props: {
    'list': { type: Array, default: () => [] },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
    'headers': { type: Array, default: () => [] },
  },
  data: () => ({
    helper: helper,
  }),
  methods: {
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    convertAmount(amount) {
      return helper.convertToBigNumber(amount / 1000000, 6)
    },
    onFindADAValue(objInput, objOutput) {
      const input =  _.filter(objInput, { 'ctaAddress': this.search })
      const output = _.filter(objOutput, { 'ctaAddress': this.search })
      const tempArr = !_.isEmpty(input) ? input : output

      if (tempArr.length === 1) {
        return helper.convertToBigNumber(tempArr[0].ctaAmount.getCoin / 1000000, 6)
      } else {
        const xSum = _.sumBy(tempArr, (o) => parseFloat(o.ctaAmount.getCoin))

        return helper.convertToBigNumber(xSum / 1000000, 6)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .highlight {
    min-width: 100px;
  }
</style>
