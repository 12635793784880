class Eos {
  url = 'https://eos.greymass.com/v1/'
  axios = require('axios')
  BigNumber = require('bignumber.js')
  addrValidator = require('multicoin-address-validator')

  instance = this.axios.create()

  axiosConfig(config = this.instance.defaults) {
    delete config.headers
    config = {
      headers: {
        'accept': '*/*',
        'content-type': 'text/plain;charset=UTF-8',
      },
    }

    return config
  }

  checkTxn(txn) {
    return /^[a-zA-Z0-9]{64}$/.test(txn)
  }

  checkAddress(addr) {
    const regex = /^[a-z0-9.]+$/g

    if (addr.search(regex) !== -1 && addr.length > 5) {
      return true
    } else {
      return false
    }
  }

  async getBalance(addr) {
    return await this.instance
      .post(`${this.url}chain/get_currency_balance`, {
        'account': addr,
        code: 'eosio.token',
        symbol: 'EOS',
      }, this.axiosConfig())
      .then(async (res) => {
        return res.data[0] ? res.data[0].split(' ')[0] : '0'
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  async getTransaction(txn) {
    return await this.instance.post(`${this.url}history/get_transaction`, {
      block_num_hint: 0,
      id: txn,
    }, this.axiosConfig(),
    ).then((res) => {
      return [res.data]
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransactions(payload) {
    return await this.instance.post(`${this.url}history/get_actions`, {
      'account_name': payload.address,
      'offset': payload.offset ? payload.offset : -100,
      'pos': payload.pos ? payload.pos : -100,
    }, this.axiosConfig(),
    ).then((res) => {
      return res.data.actions.reverse()
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransfers() {
    return []
  }

  async getTokens(payload) {
    return await this.instance.get(
      `https://www.api.bloks.io/account/${payload.address}?type=getAccountTokens&coreSymbol=EOS`,
      this.axiosConfig(),
    ).then((res) => {
      return res.data.tokens
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getNfts(payload) {
    const urls = [
      { url: `https://eos.api.simpleassets.io/v1/user/${payload.address}/assets?page=1&limit=1000&sortField=assetId&sortOrder=asc`, result: 'results' },
      { url: `https://eos.api.atomicassets.io/atomicassets/v1/assets?owner=${payload.address}&page=1&limit=1000&order=desc&sort=asset_id`, result: 'data' },
    ]

    const results = []

    for await (const [i, item] of urls.entries()) {
      const res = await this.instance.get(
        item.url,
        this.axiosConfig(),
      ).then((res) => {
        return res.data[item.result]
      }).catch((err) => {
        console.log(err)

        return []
      })

      res.forEach((o) => {
        results.push(o)
      })
    }

    return results
  }
}

module.exports.Eos = Eos
