<template>
  <div v-if="isAddress">
    <v-row>
      <v-col cols="6" md="3" lg="2">
        <v-btn-toggle
          class="fill-width d-flex v-btn-group v-btn-group--4"
          mandatory
          dense
        >
          <v-btn
            v-for="(item, idx) in btnTabs"
            :key="idx"
            :ripple="false"
            :value="item.value"
            class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
            @click="onChangeTab(item)"
          >
            {{ item.name }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        cols="6"
        md="3"
        offset-md="6"
        lg="2"
        offset-lg="8"
      >
        <v-row dense align="center">
          <v-col cols="2" lg="2" offset="8">
            <v-btn
              block
              :value="true"
              class="float-right no-hover-bg"
              @click="onPreviousPage"
            >
              <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
            </v-btn>
          </v-col>
          <v-col cols="2" lg="2">
            <v-btn
              block
              :value="false"
              class="float-right no-hover-bg"
              @click="onNextPage"
            >
              <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :items="listTransactions"
          :loading="loadingTransactions"
          :loader-height="1"
          :headers-length="10"
          no-data-text="No Record Found."
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
          hide-default-header
        >
          <template v-slot:header="">
            <thead class="v-data-table-header">
              <tr>
                <th width="15%" class="sticky">{{ $t('Hash') }} </th>
                <th width="5%">{{ $t('Block') }} </th>
                <th width="10%">{{ $t('Date') }} </th>
                <th width="15%">{{ $t('From') }}</th>
                <th width="15%">{{ $t('To') }}</th>
                <th width="10%" class="text-right">{{ $t('Amount') }}</th>
              </tr>
            </thead>
          </template>
          <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <div>
                  <a class="ellipsis body-2" @click="onGetTransaction(item.meta.txID)">
                    <span>{{ helper.truncateString(item.meta.txID, 30) }}</span>
                  </a>
                </div>
              </td>
              <td>{{ item.meta.blockNumber }}</td>
              <td class="text-left currency-details">
                <span class="ellipsis body-2">
                  {{ helper.convertUnixTime(item.meta.blockTimestamp) }}
                </span>
              </td>
              <td>
                <a v-if="item.sender !== search.toLowerCase()" class="ml-2" @click="onGetBalance(item.sender)">
                  <span class="float-left">{{ helper.truncateString(item.sender, 20) }}</span>
                </a>
                <span v-else class="float-left">{{ helper.truncateString(item.sender, 20) }}</span>
              </td>
              <td>
                <a v-if="item.recipient !== search.toLowerCase()" class="ml-2" @click="onGetBalance(item.recipient)">
                  <span class="float-left">{{ helper.truncateString(item.recipient, 20) }}</span>
                </a>
                <span v-else class="float-left">{{ helper.truncateString(item.recipient, 20) }}</span>
              </td>
              <td class="text-right">
                <v-chip
                  label
                  :class="` text-right
                    ${ item.recipient === search.toLowerCase() ? 'white--text secondary_3': 'white--text red'}
                  `"
                >
                  {{ helper.convertToBigNumber(convertAmount(item.amount), 4) }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <!-- {{ list[0].meta }} -->
    <v-card
      v-for="item in list"
      :key="item.meta.txID"
      outlined
      class="common_background elevation-0 pa-2 mb-2"
    >
      <v-row dense>
        <v-col cols="12" sm="6" class="flex-grow-1">
          <p class="mb-0 body-2">
            <span class="float-left">{{ $t('Hash') }}:</span>
            <a class="float-right text-right ellipsis" @click="onGetTransaction(item.meta.txID)">
              {{ item.meta.txID }}
            </a>
          </p>
        </v-col>
        <v-col cols="12" sm="3" class="flex-grow-0">
          <p class="mb-0 body-2">
            <span class="float-left">{{ $t('Block') }}:</span>
            <span class="float-right secondary--text">{{ item.meta.blockNumber }}</span>
          </p>
        </v-col>
        <v-col cols="12" sm="3" class="flex-grow-0">
          <p class="mb-0 body-2">
            <span class="float-left">{{ $t('Date') }}:</span>
            <span class="float-right secondary--text">
              {{ helper.convertUnixTime(new Date(item.meta.blockTimestamp)) }}
            </span>
          </p>
        </v-col>
      </v-row>
      <v-row dense class="mt-2">
        <v-col cols="12" sm="">
          <p class="body-2 mb-0">
            <span class="">{{ $t('From') }}: </span>
          </p>
        </v-col>
        <v-col cols="12" sm="6">
          <p class="body-2 mb-0">
            <span class="float-left ">{{ $t('To') }}:</span>
          </p>
        </v-col>
      </v-row>
      <v-row
        v-for="out in item.outputs"
        :key="`${out.transfers[0].sender}-${Math.random()}`"
        dense
      >
        <v-col cols="12" sm="6" class="py-0">
          <a @click="onGetBalance(out.transfers[0].sender)">
            <span class="body-2 ellipsis">{{ out.transfers[0].sender }}</span>
          </a>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <a @click="onGetBalance(out.transfers[0].recipient)">
            <span class="body-2 ellipsis">{{ out.transfers[0].recipient }}</span>
          </a>
          <span class="body-2 float-right">{{ convertAmount(out.transfers[0].amount) }}</span>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="6" >
          <p class="mb-0 body-2">
            <span class="float-left">{{ $t('Fee') }}:</span>
            <span class="float-right secondary--text">
              {{ helper.convertToBigNumber(convertAmount(item.paid), 4) }}
            </span>
          </p>
        </v-col>
        <v-col cols="12" sm="6">
          <v-chip
            label
            class="float-right subtitle-1 font-weight-bold green--text"
          >
            <span class="float-right">
              {{ addOutputs(item.outputs) }}
            </span>
          </v-chip>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import helper from '@/utils/helper'

export default {
  props: {
    'list': { type: Array, default: () => [] },
    'tokens': { type: Array, default: () => [] },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
    'currency': { type: String, default: () => '' },
  },
  data() {
    return {
      lib: require('@/utils/crypto/vet.js'),
      helper: helper,
      listTransactions: this.list,
      listTokens: this.tokens,
      pageTransactions: 0,
      loadingTransactions: false,
      rowsPerPage: 50,
      tokenSlice: 12,
      position: -1,
      selected_tab: { name: 'Transactions', value: 'transactions' },
      btnTabs: [
        { name: 'Transactions', value: 'transactions' },
      ],
      selected_action: { name: 'Actions', value: 'actions' },
      btnActions: [
        { name: 'Actions', value: 'actions' },
        { name: 'Traces', value: 'traces' },
      ],
    }
  },
  methods: {
    onLoadMoreTokens() {
      this.tokenSlice += 12
    },
    onPreviousPage() {
      if (this[`page${this.selected_tab.name}`] <= 1) {
        this[`page${this.selected_tab.name}`] = 0
      } else {
        this[`page${this.selected_tab.name}`] -= 1 * this.rowsPerPage
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`page${this.selected_tab.name}`] += 1 * this.rowsPerPage
      this.onPaginate()
    },
    async onPaginate() {
      const lib = new this.lib['Vechain']()

      this[`loading${this.selected_tab.name}`] = true

      const tempTxns = await lib.getTransactions({
        address: this.search,
        limit: this.rowsPerPage,
        offset: this[`page${this.selected_tab.name}`] * this.rowsPerPage,
      })

      this.$set(this, 'listTransactions', tempTxns)

      this[`loading${this.selected_tab.name}`] = false
    },
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    convertAmount(amount) {
      return (amount * 1) / 1e18
    },
    addOutputs(items) {
      let totalAmount = 0

      items.forEach((o) => {
        totalAmount += this.convertAmount(o.transfers[0].amount)
      })

      return helper.convertToBigNumber(totalAmount, 4)
    },
  },
}
</script>
