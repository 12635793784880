class Bitcoin {
  url = 'https://api.blockchain.info/haskoin-store/btc/'
  axios = require('axios')
  BigNumber = require('bignumber.js')
  addrValidator = require('multicoin-address-validator')
  instance = this.axios.create()

  axiosConfig(config = this.instance.defaults) {
    delete config.headers
    config = {
      headers: {
        'accept': '*/*',
        'content-type': 'text/plain;charset=UTF-8',
      },
    }

    return config
  }

  checkTxn(txn) {
    return /^[a-fA-F0-9]{64}$/.test(txn)
  }

  checkAddress(addr) {
    return this.addrValidator.validate(addr, 'BTC')
  }

  async getBalance(addr) {
    return await this.instance.get(
      `${this.url}address/${addr}/balance`, this.axiosConfig(),
    ).then(async (res) => {
      return res.data.confirmed / 100000000
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransaction(txn) {
    return await this.instance.get(
      `${this.url}transaction/${txn}`, this.axiosConfig(),
    ).then((res) => {
      return [res.data]
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransactions(payload) {
    return await this.instance.get(
      `${this.url}address/${payload.address}/transactions/full?limit=${payload.row}&offset=${payload.page}`, this.axiosConfig(),
    ).then(async (res) => {
      return res.data
    }).catch((err) => {
      console.log(err.response.data)

      return []
    })
  }

  async getTransfers(addr) {
    return []
  }

  async getTokens(addr) {
    return []
  }

}

module.exports.Bitcoin = Bitcoin
