class BinanceCoin {
  MORALIS_APP_ID = '0SvOFRBA3l7jl8hU9PtDy91kAnFVt12OcQORpRY7'
  MORALIS_SERVER = 'https://ihnoruxtk72t.usemoralis.com:2053/server'
  url = 'https://deep-index.moralis.io/api/'
  CHAIN_ID = '0x38'
  API_KEY = 'tzu8aQoBobamRQ0JGF7X1dl3YOWwSNWbDeBAY35zISpLdgtgOiLbrxpNWweCPJYz'
  Moralis = require('moralis')
  axios = require('axios')
  BigNumber = require('bignumber.js')
  addrValidator = require('multicoin-address-validator')
  store = require('vuex-pathify')
  transactions = []
  tokens = []
  erc20Txns = []
  nfts = []

  constructor() {
    this.Moralis.initialize(this.MORALIS_APP_ID)
    this.Moralis.serverURL = this.MORALIS_SERVER
  }

  axiosConfig(config = this.axios.defaults) {
    config.headers['content-type'] = 'application/json'
    config.headers['x-api-key'] = this.API_KEY

    delete config.headers['Authorization']

    return config
  }

  checkTxn(txn) {
    return /^.{64,}$/.test(txn)
  }

  checkAddress(addr) {
    return this.addrValidator.validate(addr, 'ETH')
  }

  async getBalance(addr) {
    return await this.Moralis.Web3.getAllERC20(
      { chain: this.CHAIN_ID, address: addr.toLowerCase() },
    ).then(async (res) => {
      this.store.commit('explorer/SET_ERC20_TOKENS', res.slice(1))

      this.nfts = await this.getNFTs(addr.toLowerCase())

      this.store.commit('explorer/SET_NFT_TOKENS', this.nfts)

      return res[0].balance / 1e18
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getTransactions(payload) {
    return await this.axios
      .get(
        `${this.url}historical/native/transactions?chain=bsc&chain_name=mainnet&address=${payload.address}&limit=${payload.row}&offset=${payload.page}`,
        this.axiosConfig(),
      )
      .then(async (res) => {
        if (!payload.new_req) {
          await this.getErc20Transfers(payload)
          await this.getNftTransfers(payload)
        }

        return res.data
      })
      .catch((err) => {
        throw new Error(err)
      })

    // Reserve -----

    // return await this.Moralis.Web3.getTransactions({
    //   chain: this.CHAIN_ID,
    //   address: payload.address,
    //   order: 'desc',
    //   offset: payload.page,
    //   limit: payload.row,
    // }).then(async (res) => {
    //   return res
    // }).catch((err) => {
    //   throw new Error(err)
    // })
  }

  async getTransaction(txn) {
    return []
  }

  async getTransfers(addr) {
    return []
  }

  async getTokens(payload) {
    return this.tokens
  }

  async getTokenInfo(addr) {
    return await this.axios
      .get(
        `${this.url}token/ERC20/metadata?chain=bsc&chain_name=mainnet&addresses=${addr}`,
        this.axiosConfig(),
      )
      .then(async (res) => {
        return res.data[0]
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  async getNFTs(addr) {
    return await this.Moralis.Web3.getNFTs(
      { chain: this.CHAIN_ID, address: addr.toLowerCase() },
    ).then(async (res) => {
      return res
    }).catch((err) => {
      throw new Error(err)
    })
  }

  async getErc20Transfers(payload) {
    return await this.axios
      .get(
        `${this.url}historical/token/erc20/transactions?chain=bsc&chain_name=mainnet&address=${payload.address}&limit=${payload.row}&offset=${payload.page}`,
        this.axiosConfig(),
      )
      .then(async (res) => {
        this.store.commit('explorer/SET_ERC20_TXNS', res.data)

        return res.data
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  async getNftTransfers(payload) {
    return await this.axios
      .get(
        `${this.url}historical/nft/${payload.address.toLowerCase()}/transfers?chain=bsc&chain_name=mainnet&limit=${payload.row}&offset=${payload.page}`,
        this.axiosConfig(),
      )
      .then(async (res) => {
        this.store.commit('explorer/SET_NFT_TXNS', res.data.result)

        return res.data.result
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

}

module.exports.BinanceCoin = BinanceCoin
