<template>
  <v-row>
    <v-col v-if="isAddress">
      <v-row>
        <v-col>
          <v-btn-toggle
            class="v-btn-group v-btn-group--2"
            mandatory
            dense
          >
            <v-btn
              v-for="(item, idx) in tokenTabs"
              :key="idx"
              :ripple="false"
              :value="item.value"
              class="flex-grow-1 text-capitalize body-2 no-hover-bg"
              @click="onChangeTokenTab(item)"
            >
              <span class="px-2">{{ item.name }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-divider class="my-2 my-md-5"/>
      <v-row>
        <v-col v-if="selectedTokenTab.value === 'erc20Tokens'">
          <v-card
            class="common_background elevation-0 pa-2 body-2"
          >
            <v-row dense>
              <v-col v-if="erc20Tokens.length < 1">
                {{ $t('NoFound', [$t('Token')]) }}
              </v-col>
              <v-col
                v-for="token in erc20Tokens.slice(0, tokenSlice)"
                v-else
                :key="token.tokenAddress"
                cols="6"
                sm="4"
                md="3"
                lg="2"
              >
                <v-card
                  class="common_background elevation-0 pa-2"
                >
                  <div >
                    <a class="text-right adjust-center" @click="onGetBalance(token.tokenAddress)">
                      <img
                        class="token-img mr-1"
                        :lazy-src="helper.extractEosWaxTokenImgUrl(token.logo)"
                        :src="helper.extractEosWaxTokenImgUrl(token.logo)"
                        @error="helper.imgerror"
                      />
                      <span class="mb-0">{{ token.name }}</span>
                    </a>
                  </div>
                  <p class="subtitle-2 mb-0 caption">
                    <span class=" bold">{{ convertAmount(token.balance, token.decimals) }} {{ token.symbol }}</span>
                  </p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="elevation-0 issue"
                        v-bind="attrs"
                        v-on="on"
                        @click="onCopyAddress($event, token.tokenAddress)"
                      >
                        <v-icon small class="grey--text">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ token.tokenAddress }}
                    </span>
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="tokenSlice <= erc20Tokens.length">
              <v-col class="text-center">
                <v-btn class="primary uncapitalize body-2 px-4" @click="onLoadMoreTokens()">
                  <v-icon>mdi-refresh</v-icon>
                  <span class="">{{ $t('LoadMore') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-if="selectedTokenTab.value === 'nftTokens'">
          <v-card
            class="common_background elevation-0 pa-2 body-2"
          >
            <v-row>
              <v-col v-if="nftTokens.length < 1">
                {{ $t('NoFound', [$t('Token')]) }}
              </v-col>
              <v-col
                v-for="token in nftTokens.slice(0, nftSlice)"
                v-else
                :key="token.token_id"
                cols="6"
                sm="4"
                md="3"
                lg="2"
              >
                <v-card
                  class="common_background elevation-0 pa-2"
                >
                  <div >
                    <a class="text-right adjust-center" @click="onGetBalance(token.tokenAddress)">
                      <img
                        class="token-img mr-1"
                        :lazy-src="helper.extractEosWaxTokenImgUrl(token.logo)"
                        :src="helper.extractEosWaxTokenImgUrl(token.logo)"
                        @error="helper.imgerror"
                      />

                      <span class="mb-0">{{ token.name }}</span>
                    </a>
                  </div>
                  <p class="subtitle-2 mb-0 caption">
                    <span class=" bold">{{ convertAmount(token.balance, token.decimals) }} {{ token.symbol }}</span>
                  </p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="elevation-0 issue"
                        v-bind="attrs"
                        v-on="on"
                        @click="onCopyAddress($event, token.token_address)"
                      >
                        <v-icon small class="grey--text">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ token.token_address }}
                    </span>
                  </v-tooltip>
                  <span class="ml-1 caption grey--text">Token Id: {{ token.token_id }}</span>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="nftTokens.length > 12" dense>
              <v-col class="text-center">
                <!-- {{ nftTokens.length <== 12 }} -->
                <v-btn large class="primary uncapitalize body-2 px-4" @click="onLoadMoreNftTokens()">
                  <v-icon>mdi-refresh</v-icon>
                  <span class="">{{ $t('LoadMore') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="isAddress">
        <v-col cols="6" md="3" lg="2">
          <v-btn-toggle
            class="fill-width d-flex v-btn-group v-btn-group--4"
            mandatory
            dense
          >
            <v-btn
              v-for="(item, idx) in btnTabs"
              :key="idx"
              :ripple="false"
              :value="item.value"
              class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
              @click="onChangeTxnTab(item)"
            >
              {{ item.name }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          cols="6"
          md="3"
          offset-md="6"
          lg="2"
          offset-lg="8"
        >
          <v-row dense align="center">
            <v-col cols="2" lg="2" offset="8">
              <v-btn
                block
                :value="true"
                class="float-right no-hover-bg common_background"
                @click="onPreviousPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
              </v-btn>
            </v-col>
            <v-col cols="2" lg="2">
              <v-btn
                block
                :value="false"
                class="float-right no-hover-bg common_background"
                @click="onNextPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="selectedTxnTab.value === 'Transactions'" cols="12">
          <v-data-table
            :items="listTransactions"
            :loading="loadingTransactions"
            :loader-height="1"
            :headers-length="7"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            hide-default-header
          >
            <template v-slot:header="">
              <thead class="v-data-table-header">
                <tr>
                  <th width="15%" class="sticky">{{ $t('Hash') }} </th>
                  <th width="8%">{{ $t('Block') }} </th>
                  <th width="10%">{{ $t('Date') }} </th>
                  <th width="5%">{{ $t('Action') }}</th>
                  <th width="10%">{{ $t('From') }}</th>
                  <th width="10%">{{ $t('To') }}</th>
                  <th width="5%" class="text-center">{{ $t('Success') }}</th>
                  <th width="10%" class="text-right">{{ $t('Amount') }}</th>
                </tr>
              </thead>
            </template>
            <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          icon
                          class="elevation-0 issue"
                          v-bind="attrs"
                          v-on="on"
                          @click="onCopyAddress($event, item.hash)"
                        >
                          <v-icon small class="grey--text">mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span class="body-2">
                        {{ item.hash }}
                      </span>
                    </v-tooltip>
                    <span class="ml-1">{{ helper.truncateString(item.hash, 30) }}</span>
                  </div>
                </td>
                <td>
                  <span class="body-2">{{ item.block_number > 0 ? item.block_number : '' }}</span>
                </td>
                <td>
                  <span class="body-2">{{ helper.nonUTCDate(new Date(item.block_timestamp)) }}</span>
                </td>
                <td>
                  <v-chip
                    label
                    :class="`body-2
                      ${ item.to_address === search.toLowerCase() ? 'secondary_3--text highlight--success': 'red--text highlight--failed'}
                    `"
                  >
                    {{ `${ item.to_address === search.toLowerCase() ? $t('Received') : $t('Sent') }` }}
                  </v-chip>
                </td>
                <td>
                  <a v-if="item.from_address !== search.toLowerCase() " class="ellipsis" @click="onGetBalance(item.from_address)">
                    <span class="body-2">{{ helper.truncateString(item.from_address, 30) }}</span>
                  </a>
                  <span v-else class="body-2">{{ helper.truncateString(item.from_address, 30) }}</span>
                </td>
                <td>
                  <a v-if="item.to_address !== search.toLowerCase() " class="ellipsis" @click="onGetBalance(item.to_address)">
                    <span class="body-2">{{ helper.truncateString(item.to_address, 30) }}</span>
                  </a>
                  <span v-else class="body-2">{{ helper.truncateString(item.to_address, 30) }}</span>
                </td>
                <td class="text-center">
                  <v-icon v-if="item.receipt_status === '1'" class="secondary_3--text">
                    mdi-checkbox-marked-outline
                  </v-icon>
                  <v-icon v-else class="red--text">
                    mdi-close-box-outline
                  </v-icon>
                </td>
                <td class="text-right">
                  <v-chip
                    label
                    :class="` text-right
                      ${ item.to_address === search.toLowerCase() ? 'white--text secondary_3': 'white--text red'}
                    `"
                  >
                    {{ convertTxnAmount(item.value) }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-if="selectedTxnTab.value === 'Erc20Txns'" cols="12">
          <v-data-table
            :items="erc20Txns"
            :loading="loadingErc20Txns"
            :loader-height="1"
            :headers-length="8"
            no-data-text="No Record Found."
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            hide-default-header
          >
            <template v-slot:header="">
              <thead class="v-data-table-header">
                <tr>
                  <th width="10%" class="sticky">{{ $t('Hash') }} </th>
                  <th width="5%">{{ $t('Block') }} </th>
                  <th width="8%">{{ $t('Date') }} </th>
                  <th width="5%">{{ $t('Action') }}</th>
                  <th width="10%">{{ $t('Token') }} {{ $t('Address') }}</th>
                  <th width="10%">{{ $t('From') }}</th>
                  <th width="10%">{{ $t('To') }}</th>
                  <th width="10%" class="text-right">{{ $t('Amount') }}</th>
                </tr>
              </thead>
            </template>
            <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="elevation-0 issue"
                        v-bind="attrs"
                        v-on="on"
                        @click="onCopyAddress($event, item.transaction_hash)"
                      >
                        <v-icon small class="grey--text">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ item.transaction_hash }}
                    </span>
                  </v-tooltip>
                  <span class="ml-1">{{ helper.truncateString(item.transaction_hash, 30) }}</span>
                </td>
                <td>
                  <span class="body-2">{{ item.block_number > 0 ? item.block_number : '' }}</span>
                </td>
                <td>
                  <span class="body-2">{{ helper.nonUTCDate(new Date(item.block_timestamp)) }}</span>
                </td>
                <td>
                  <v-chip
                    label
                    :class="`body-2
                      ${ item.to_address === search.toLowerCase() ? 'secondary_3--text highlight--success': 'red--text highlight--failed'}
                    `"
                  >
                    {{ `${ item.to_address === search.toLowerCase() ? $t('Received') : $t('Sent') }` }}
                  </v-chip>
                </td>
                <td>
                  <a @click="onGetBalance(item.address)">
                    <span class="body-2">{{ helper.truncateString(item.address, 30) }}</span>
                  </a>
                </td>
                <td>
                  <a v-if="item.from_address !== search.toLowerCase() " class="ellipsis" @click="onGetBalance(item.from_address)">
                    <span class="body-2">{{ helper.truncateString(item.from_address, 30) }}</span>
                  </a>
                  <span v-else class="body-2">{{ helper.truncateString(item.from_address, 30) }}</span>
                </td>
                <td>
                  <a v-if="item.to_address !== search.toLowerCase() " class="ellipsis" @click="onGetBalance(item.to_address)">
                    <span class="body-2">{{ helper.truncateString(item.to_address, 30) }}</span>
                  </a>
                  <span v-else class="body-2">{{ helper.truncateString(item.to_address, 30) }}</span>
                </td>
                <td class="text-right">
                  <v-chip
                    label
                    :class="` text-right
                      ${ item.to_address === search.toLowerCase() ? 'white--text secondary_3': 'white--text red'}
                    `"
                  >
                    {{ convertErc20TxnAmount(item.address, item.value) }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-if="selectedTxnTab.value === 'NftTxns'" cols="12">
          <v-data-table
            :items="nftTxns"
            :loading="loadingNftTxns"
            :loader-height="1"
            :headers-length="8"
            no-data-text="No Record Found."
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            hide-default-header
          >
            <template v-slot:header="">
              <thead class="v-data-table-header">
                <tr>
                  <th width="10%" class="sticky">{{ $t('Hash') }} </th>
                  <th width="5%">{{ $t('Block') }} </th>
                  <th width="8%">{{ $t('Date') }} </th>
                  <th width="5%" >{{ $t('Action') }}</th>
                  <th width="10%">{{ $t('Token') }} {{ $t('Address') }}</th>
                  <th width="10%">{{ $t('From') }}</th>
                  <th width="10%">{{ $t('To') }}</th>
                  <th width="10%" class="text-right">{{ $t('Amount') }}</th>
                </tr>
              </thead>
            </template>
            <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="elevation-0 issue"
                        v-bind="attrs"
                        v-on="on"
                        @click="onCopyAddress($event, item.transaction_hash)"
                      >
                        <v-icon small class="grey--text">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ item.transaction_hash }}
                    </span>
                  </v-tooltip>
                  <span class="ml-1">{{ helper.truncateString(item.transaction_hash, 30) }}</span>
                </td>
                <td>
                  <span class="body-2">{{ item.block_number > 0 ? item.block_number : '' }}</span>
                </td>
                <td>
                  <span class="body-2">{{ helper.nonUTCDate(new Date(item.block_timestamp)) }}</span>
                </td>
                <td>
                  <v-chip
                    label
                    :class="`body-2
                      ${ item.to_address === search.toLowerCase() ? 'secondary_3--text highlight--success': 'red--text highlight--failed'}
                    `"
                  >
                    {{ `${ item.to_address === search.toLowerCase() ? $t('Received') : $t('Sent') }` }}
                  </v-chip>
                </td>
                <td>
                  <a @click="onGetBalance(item.address)">
                    <span class="body-2">{{ helper.truncateString(item.address, 30) }}</span>
                  </a>
                </td>
                <td>
                  <a v-if="item.from_address !== search.toLowerCase() " class="ellipsis" @click="onGetBalance(item.from_address)">
                    <span class="body-2">{{ helper.truncateString(item.from_address, 30) }}</span>
                  </a>
                  <span v-else class="body-2">{{ helper.truncateString(item.from_address, 30) }}</span>
                </td>
                <td>
                  <a v-if="item.to_address !== search.toLowerCase() " class="ellipsis" @click="onGetBalance(item.to_address)">
                    <span class="body-2">{{ helper.truncateString(item.to_address, 30) }}</span>
                  </a>
                  <span v-else class="body-2">{{ helper.truncateString(item.to_address, 30) }}</span>
                </td>
                <td class="text-right">
                  <v-chip
                    label
                    :class="` text-right
                      ${ item.to_address === search.toLowerCase() ? 'white--text secondary_3': 'white--text red'}
                    `"
                  >
                    {{ convertErc20TxnAmount(item.address, item.value) }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <p class="my-3 red--text font-weight-medium text-center">{{ $t('TransactionNotSupported') }}</p>
    </v-col>
  </v-row>
</template>

<script>
import helper from '@/utils/helper'
import { get, call, commit, dispatch } from 'vuex-pathify'

export default {
  props: {
    'transactions': { type: Array, default: () => [] },
    'tokens': { type: Array, default: () => [] },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
  },
  data() {
    return {
      lib: require('@/utils/crypto/bsc.js'),
      helper: helper,
      listTransactions: this.transactions,
      listTokens: this.tokens ? this.removeTokens(this.tokens) : [],
      pageTransactions: 0,
      pageErc20Txns: 0,
      pageNftTxns: 0,
      loadingTransactions: false,
      loadingErc20Txns: false,
      loadingNftTxns: false,
      rowsPerPage: 50,
      tokenTab: 0,
      tokenSlice: 12,
      nftSlice: 12,
      selectedTokenTab: { name: 'Erc20 Tokens', value: 'erc20Tokens' },
      tokenTabs: [
        { name: 'Erc20 Tokens', value: 'erc20Tokens' },
        { name: 'NFT Tokens', value: 'nftTokens' },
      ],
      selectedTxnTab: { name: 'Transactions', value: 'Transactions' },
      btnTabs: [
        { name: 'Transactions', value: 'Transactions' },
        { name: 'ERC20 Txns', value: 'Erc20Txns' },
        { name: 'NFT Txns', value: 'NftTxns' },
      ],
    }
  },
  computed: {
    erc20Tokens: get('explorer/getErc20Tokens'),
    erc20Txns: get('explorer/getErc20Txns'),
    nftTokens: get('explorer/getNftTokens'),
    nftTxns: get('explorer/getNftTxns'),
  },
  methods: {
    onChangeTokenTab(item) {
      this.selectedTokenTab = item
    },
    onChangeTxnTab(item) {
      this.selectedTxnTab = item
    },
    onCopyAddress(ev, string) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      ev.path[0].parentNode.parentNode.classList.add('green', 'darken-1')
      ev.path[0].classList.remove('mdi-content-copy', 'grey--text')
      ev.path[0].classList.add('mdi-check', 'green--text')

      clipboardData.writeText(string)

      setTimeout(async () => {
        ev.path[0].parentNode.parentNode.classList.remove('green', 'darken-1')
        ev.path[0].classList.remove('mdi-check', 'green--text')
        ev.path[0].classList.add('mdi-content-copy', 'grey--text')
      }, 700)
    },
    onLoadMoreTokens() {
      this.tokenSlice += 12
    },
    onLoadMoreNftTokens() {
      this.nftSlice += 12
    },
    onPreviousPage() {
      if (this[`page${this.selectedTxnTab.value}`] <= 1) {
        this[`page${this.selectedTxnTab.value}`] = 0
      } else {
        this[`page${this.selectedTxnTab.value}`] -= 1 * this.rowsPerPage
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`page${this.selectedTxnTab.value}`] += 1 * this.rowsPerPage
      this.onPaginate()
    },
    async onPaginate() {
      const lib = new this.lib['BinanceCoin']()

      this[`loading${this.selectedTxnTab.value}`] = true

      console.log(this.selectedTxnTab.value, this[`page${this.selectedTxnTab.value}`])

      if (this.selectedTxnTab.value === 'Transactions') {
        const tempTxns = await lib.getTransactions({
          row: this.rowsPerPage,
          page: this[`page${this.selectedTxnTab.value}`],
          address: this.search,
          new_req: true,
        })

        this.listTransactions = tempTxns
      }
      else if (this.selectedTxnTab.value === 'Erc20Txns') {
        const tempTxns = await lib.getErc20Transfers({
          row: this.rowsPerPage,
          page: this[`page${this.selectedTxnTab.value}`],
          address: this.search,
          new_req: true,
        })

        this.loadingErc20Txns = tempTxns
      } else {
        const tempTxns = await lib.getNftTransfers({
          row: this.rowsPerPage,
          page: this[`page${this.selectedTxnTab.value}`],
          address: this.search,
          new_req: true,
        })
      }

      this[`loading${this.selectedTxnTab.value}`] = false
    },
    removeTokens(arr) {
      return arr.filter((item, index) => {
        return item.token_name !== '' && item.token_symbol !== ''
      })
    },
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    convertAmount(amount, decimal) {
      const rounded = amount / helper.padTrailingZeros(1, decimal + 1)

      return helper.convertToBigNumber(rounded, rounded < 0.01 ? 6 : 2)
    },
    convertTxnAmount(amount) {
      return helper.convertToBigNumber(amount / 1e18, 6)
    },
    convertErc20TxnAmount(tokenAddress, amount) {
      let tokenInfo

      if (amount > 0) {
        tokenInfo = this.erc20Tokens.find((o) => o.tokenAddress === tokenAddress)

        if (tokenInfo) {
          const rounded = amount / helper.padTrailingZeros(1, tokenInfo.decimals + 1)

          return helper.convertToBigNumber(rounded, rounded < 0.01 ? tokenInfo.decimals : 2)
        } else {
          return amount / 1e18
        }
      } else return 0
    },
    convertGas(gas, gasPrice) {
      return (gas * gasPrice) / 1e18
    },
  },
}
</script>

<style lang="scss" scoped>
.issue {
  transition: 2s linear all;
}
</style>
