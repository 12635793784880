<template>
  <v-row>
    <v-col v-if="isAddress" cols="12">
      <v-row dense>
        <v-col cols="12" md="4" lg="3">
          <v-btn-toggle
            v-model="selected_tab.value"
            class="fill-width d-flex v-btn-group v-btn-group--2"
            mandatory
            dense
          >
            <v-btn
              v-for="(item, idx) in btnTabs"
              :key="idx"
              :ripple="false"
              :value="item.value"
              class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
              @click="onChangeTab(item)"
            >
              {{ item.name }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          cols="12"
          md="3"
          offset-md="5"
          lg="2"
          offset-lg="7"
        >
          <v-row dense align="center">
            <v-col cols="2" lg="2" offset="8">
              <v-btn
                block
                :value="true"
                class="float-right no-hover-bg"
                @click="onPreviousPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
              </v-btn>
            </v-col>
            <v-col cols="2" lg="2">
              <v-btn
                block
                :value="false"
                class="float-right no-hover-bg"
                @click="onNextPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="selected_tab.value === 'transactions'" cols="12">
          <v-data-table
            :items="listTransactions.count > 0 ? listTransactions.extrinsics : []"
            :loading="loadingTransactions"
            :loader-height="1"
            :headers-length="9"
            hide-default-footer
            hide-default-header
            disable-pagination
            mobile-breakpoint="0"
          >
            <template v-slot:header="">
              <thead class="v-data-table-header">
                <tr>
                  <th width="20%" class="sticky">{{ $t('Hash') }} </th>
                  <th width="10%">{{ $t('ExtrinsicID') }} </th>
                  <th width="10%">{{ $t('Block') }} </th>
                  <th width="10%">{{ $t('Date') }} </th>
                  <th width="5%">{{ $t('Module') }} </th>
                  <th width="5%">{{ $t('Call') }} </th>
                  <th width="5%" class="text-center">{{ $t('Success') }}</th>
                  <th width="10%" class="text-right">{{ $t('Fee') }}</th>
                </tr>
              </thead>
            </template>
            <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left currency-details sticky">
                  <div>
                    <a class="ellipsis body-2" @click="onGetTransaction(`0x${item.extrinsic_hash.substring(2)}`)">
                      <span>{{ helper.truncateString(item.extrinsic_hash, 30) }}</span>
                    </a>
                  </div>
                </td>
                <td class="body-2">
                  <span class="body-2">{{ item.extrinsic_index }}</span>
                </td>
                <td class="body-2">
                  {{ item.block_num }}
                </td>
                <td class="body-2">
                  {{ helper.convertUnixTime(item.block_timestamp) }}
                </td>
                <td class="body-2">
                  {{ item.call_module }}
                </td>
                <td class="body-2">
                  {{ item.call_module_function }}
                </td>
                <td class="text-center">
                  <v-icon v-if="item.success" class="secondary_3--text">
                    mdi-checkbox-marked-outline
                  </v-icon>
                  <v-icon v-else class="red--text">
                    mdi-close-box-outline
                  </v-icon>
                </td>
                <td class="text-right body-2">
                  <span>{{ convertAmount(item.fee) }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-if="selected_tab.value === 'transfers'" cols="12">
          <v-data-table
            :items="listTransfers.count > 0 ? listTransfers.transfers : []"
            :loading="loadingTransfers"
            :loader-height="1"
            :headers-length="9"
            hide-default-footer
            hide-default-header
            disable-pagination
            mobile-breakpoint="0"
          >
            <template v-slot:header="">
              <thead class="v-data-table-header">
                <tr>
                  <th width="15%" class="sticky">{{ $t('Hash') }} </th>
                  <th width="10%">{{ $t('ExtrinsicID') }} </th>
                  <th width="10%">{{ $t('Block') }} </th>
                  <th width="10%">{{ $t('Date') }} </th>
                  <th width="5%">{{ $t('Action') }}</th>
                  <th width="15%">{{ $t('From') }}</th>
                  <th width="15%">{{ $t('To') }}</th>
                  <th width="10%" class="text-right">{{ $t('Fee') }}</th>
                  <th width="10%" class="text-right">{{ $t('Amount') }}</th>
                </tr>
              </thead>
            </template>
            <template slot="no-data">{{ $t('NoTransactionFound') }}</template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left currency-details sticky">
                  <div>
                    <a class="ellipsis body-2" @click="onGetTransaction(item.hash)">
                      <span>{{ helper.truncateString(item.hash, 30) }}</span>
                    </a>
                  </div>
                </td>
                <td class="body-2">
                  <span class="body-2">{{ item.extrinsic_index }}</span>
                </td>
                <td class="body-2">
                  {{ item.block_num }}
                </td>
                <td class="body-2">
                  {{ helper.convertUnixTime(item.block_timestamp) }}
                </td>
                <td class="text-left">
                  <v-chip
                    v-if="isAddress"
                    label
                    :class="`body-2
                      ${ item.to === search ? 'secondary_3--text highlight--success': 'red--text highlight--failed'}
                    `"
                  >
                    {{ `${ item.to === search ? $t('Received') : $t('Sent') }` }}
                  </v-chip>
                </td>
                <td class="text-left body-2">
                  <a v-if="item.from !== search" class="ellipsis body-2" @click="onGetBalance(item.from)">
                    <span>{{ helper.truncateString(item.from, 30) }}</span>
                  </a>
                  <span v-else>{{ helper.truncateString(item.from, 30) }}</span>
                </td>
                <td class="text-left body-2">
                  <a v-if="item.to !== search" class="ellipsis body-2" @click="onGetBalance(item.to)">
                    <span>{{ helper.truncateString(item.to, 30) }}</span>
                  </a>
                  <span v-else>{{ helper.truncateString(item.to, 30) }}</span>
                </td>
                <td class="text-right body-2">
                  <span>{{ convertAmount(item.fee) }}</span>
                </td>
                <td class="text-right body-2">
                  <v-chip
                    label
                    :class="`body-2
                      ${ item.to === search ? 'white--text secondary_3': 'white--text red'}
                    `"
                  >
                    <span>{{ helper.convertToBigNumber(item.amount, 8) }}</span>
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-card
        outlined
        class="common_background elevation-0 pa-2 mb-2"
      >
        <v-card-title class="px-2 py-1">
          <h4 class="subtitle-1">
            {{ `${$t("Transaction")} ${$t("Details")}` }}
          </h4>
        </v-card-title>
        <v-card-text class="pa-sm-2">
          <v-row dense class="my-2">
            <v-col cols="12" sm="6" class="flex-grow-1">
              <p class="mb-0">
                <span class=" float-left">{{ $t('Hash') }}:</span>
                <span class=" float-right secondary--text">{{ listTransactions.extrinsic_hash }}</span>
              </p>
            </v-col>
            <v-col cols="12" sm="3" class="flex-grow-0">
              <p class="mb-0">
                <span class=" float-left">{{ $t('Block') }}:</span>
                <span class=" float-right secondary--text">{{ listTransactions.block_num }}</span>
              </p>
            </v-col>
            <v-col cols="12" sm="3" class="flex-grow-0">
              <p class="mb-0">
                <span class=" float-left">{{ $t('Date') }}:</span>
                <span class=" float-right secondary--text">
                  {{ helper.convertUnixTime(listTransactions.block_timestamp, true) }}
                </span>
              </p>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" sm="6">
              <div class="mb-0">
                <span>{{ $t('From') }}: </span>
              </div>
              <div>
                <a class="ml-2" @click="onGetBalance(listTransactions.account_id)">
                  <span class="float-left">{{ listTransactions.account_id }}</span>
                </a>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="mb-0">
                <span>{{ $t('To') }}: </span>
              </div>
              <div
                v-for="item in listTransactions.params[0].value"
                :key="item.params[0].value.Id"
                class="d-flex justify-space-between mb-1"
              >
                <div class="float-left">
                  <a class="ml-2" @click="onGetBalance(encodeAddress(`0x${item.params[0].value.Id}`, 0))">
                    <span class="float-left">{{ encodeAddress(`0x${item.params[0].value.Id}`, 0) }}</span>
                  </a>
                </div>
                <div class="float-right">
                  {{ convertAmount(item.params[1].value) }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { encodeAddress } from '@polkadot/util-crypto'
import helper from '@/utils/helper'
import Menu from '@/components/atoms/Menu'

export default {
  components: {
  },
  props: {
    'transactions': { type: Object, default: () => {} },
    'transfers': { type: Object, default: () => {} },
    'isAddress': { type: Boolean, default: () => false },
    'search': { type: String, default: () => '' },
  },
  data() {
    return {
      lib: require('@/utils/crypto/dot.js'),
      helper: helper,
      encodeAddress: encodeAddress,
      listTransactions: this.transactions,
      listTransfers: this.transfers,
      selected_tab: { name: 'Transactions', value: 'transactions' },
      pageTransfers: 0,
      pageTransactions: 0,
      loadingTransfers: false,
      loadingTransactions: false,
      rowsPerPage: 100,
      btnTabs: [
        { name: 'Transactions', value: 'transactions' },
        { name: 'Transfers', value: 'transfers' },
      ],
    }
  },
  methods: {
    onChangeRowsPerPage(item) {
      this.rowsPerPage = item
      this.onPaginate()
    },
    onPreviousPage() {
      if (this[`page${this.selected_tab.name}`] <= 1) {
        this[`page${this.selected_tab.name}`] = 1
      } else {
        this[`page${this.selected_tab.name}`] -= 1
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`page${this.selected_tab.name}`] += 1
      this.onPaginate()
    },
    async onPaginate() {
      const lib = new this.lib['Polkadot']()

      this[`loading${this.selected_tab.name}`] = true

      if (this.selected_tab.value === 'transactions') {
        const tempTxns = await lib.getTransactions({
          row: this.rowsPerPage,
          page: this[`page${this.selected_tab.name}`],
          address: this.search,
        })

        this.listTransactions = tempTxns
      } else {
        const tempTxns = await lib.getTransfers({
          row: this.rowsPerPage,
          page: this[`page${this.selected_tab.name}`],
          address: this.search,
        })

        this.listTransfers = tempTxns
      }

      this[`loading${this.selected_tab.name}`] = false
    },
    onChangeTab(item) {
      this.selected_tab = item
    },
    onGetBalance(addr) {
      this.$emit('onGetBalance', addr)
    },
    onGetTransaction(hash) {
      this.$emit('onGetTransaction', hash)
    },
    convertAmount(amount) {
      return amount / 10000000000
    },
  },
}
</script>

<style lang="scss" scoped>
.currency-logo {
  height: 25px;
}

.currency-details {
  &:hover {
    cursor: pointer;
  }
}

.snackbar-icon {
  float: right;
  padding: 5px 0;
}
</style>
